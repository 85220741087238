import servers from './servers';

const auth = (
  server: string,
  username: string,
  password: string,
  vendor: string,
): Promise<UserData> => {
  const authData = btoa(`${vendor}:${username}:${password}`);
  const serverData = servers.find((s) => s.value === server);
  if (!serverData) {
    return Promise.reject('invalid server selection');
  }
  const request = {
    method: 'POST',
    headers: {
      Authorization: `Basic ${authData}`,
    },
  };
  return fetch(serverData.path, request)
    .then((res) => res.json())
    .then((res) => {
      if (res.statusCode && res.statusCode === '403') {
        return Promise.reject('auth error');
      }
      if (!res.cognitoProfile || !res.cognitoProfile.Token) {
        return Promise.reject('auth error');
      }
      return {
        token: res.cognitoProfile.Token,
        vendor,
        account: username,
        environment: serverData.label,
        serverData,
      };
    })
    .catch(() => {
      return Promise.reject('auth error');
    });
};

const platformServices = {
  auth,
};

export default platformServices;
