import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  Typography,
} from '@mui/material';

import ExpandableSection from '../../../../Components/ExpandableSection';

import PepperDeviceTemplate, { ConfigProps } from './Template';
import IroColorPickerTooltip from '../../../../Partials/IroColorPickerTooltip';
import IroColorPicker from '../../../../Partials/IroColorPicker';

//----------------------------------------------------------------------------------------------------------------------

const sceneModeOptions = [
  { key: 'None', value: 0 },
  { key: 'Static', value: 1 },
  { key: 'Static Transition', value: 2 },
  { key: 'Gradient Block', value: 3 },
  { key: 'Gradient All', value: 4 },
  { key: 'Jump Block', value: 5 },
  { key: 'Jump All', value: 6 },
  { key: 'Breathe BLock', value: 7 },
  { key: 'Breathe All', value: 8 },
  { key: 'Flashing Block', value: 9 },
  { key: 'Flashing All', value: 10 },
  { key: 'Flowing CW', value: 11 },
  { key: 'Flowing CCW', value: 12 },
  { key: 'Rainbow CW', value: 13 },
  { key: 'Rainbow CCW', value: 14 },
  { key: 'Music Rhythm Auto', value: 15 },
  { key: 'Music Rhythm User', value: 16 },
  { key: 'Music Roll CW Auto', value: 17 },
  { key: 'Music Roll CW User', value: 18 },
  { key: 'Music Roll CWW Auto', value: 19 },
  { key: 'Music Roll CWW User', value: 20 },
  { key: 'Music Energy Auto', value: 21 },
  { key: 'Music Energy User', value: 22 },
  { key: 'Music Spectrum Auto', value: 23 },
  { key: 'Music Spectrum User', value: 24 },
  { key: 'Music App Sync', value: 25 },
];

const ledPatternOptions = [
  { key: 'none', value: 'none' },
  { key: 'film', value: 'film' },
  { key: 'dating', value: 'dating' },
  { key: 'trailing', value: 'trailing' },
  { key: 'christmas', value: 'christmas' },
  { key: 'chase', value: 'chase' },
  { key: 'colorful', value: 'colorful' },
  { key: 'flutter', value: 'flutter' },
  { key: 'follow', value: 'follow' },
  { key: 'rainbow', value: 'rainbow' },
  { key: 'streaming', value: 'streaming' },
  { key: 'custom', value: 'custom' },
];

//----------------------------------------------------------------------------------------------------------------------

export default function RGBStripValidation(props: ValidationProps) {
  return (
    <PepperDeviceTemplate<RGBICStripConfig>
      moveClient={props.moveClient}
      onChangeDevice={props.onChangeDevice}
      device={props.device}
      Config={RGBICStrip}
      transformInitialConfig={(config: RGBICStripConfig): RGBICStripConfig => {
        if (!config.ledSegments) {
          config.ledSegments = getEmptySegments();
        }
        return config;
      }}
    />
  );
}

//----------------------------------------------------------------------------------------------------------------------

const getEmptySegments = (): string[] => {
  const segments: string[] = [];
  while (segments.length !== 15) {
    segments.push('');
  }
  return segments;
};

const RGBICStrip: React.FC<ConfigProps<RGBICStripConfig>> = (props: {
  device: MoveDevice;
  config: RGBICStripConfig;
  onConfigChange: (moveData: Partial<RGBICStripConfig>) => void;
}) => {
  const getLedStripLength = () => (
    <Box width={1}>
      <Typography variant="caption">LED Strip Length</Typography>
      <Box px={2}>
        <Slider
          color="secondary"
          value={props.config.ledStripLength || 0}
          onChangeCommitted={(
            event: React.SyntheticEvent | Event,
            value: number | number[],
          ) => {
            props.onConfigChange({
              ledStripLength: value as number,
            });
          }}
          step={1}
          min={0}
          max={50}
          valueLabelDisplay="auto"
          marks={[
            { value: 0, label: '0' },
            { value: 50, label: '50' },
          ]}
        />
      </Box>
    </Box>
  );

  const getTemperatureConfig = () => (
    <Box width={1}>
      <Typography variant="caption">Color Temperature</Typography>
      <Box px={2}>
        <Slider
          color="secondary"
          value={props.config.bulbColorTemp || 2700}
          onChangeCommitted={(
            event: React.SyntheticEvent | Event,
            value: number | number[],
          ) => {
            props.onConfigChange({
              bulbColor: '0',
              bulbColorTemp: value as number,
            });
          }}
          step={1}
          valueLabelDisplay="auto"
          marks={[
            { value: 2700, label: '2700' },
            { value: 6500, label: '6500' },
          ]}
          min={2700}
          max={6500}
        />
      </Box>
    </Box>
  );

  const getBrightnessConfig = () => (
    <Box width={1}>
      <Typography variant="caption">Brightness</Typography>
      <Box px={2}>
        <Slider
          color="secondary"
          value={props.config.bulbBrightness || 0}
          onChangeCommitted={(
            event: React.SyntheticEvent | Event,
            value: number | number[],
          ) => {
            props.onConfigChange({
              bulbBrightness: value as number,
            });
          }}
          step={1}
          valueLabelDisplay="auto"
          marks={[
            { value: 0, label: '0' },
            { value: 50, label: '50' },
            { value: 100, label: '100' },
          ]}
        />
      </Box>
    </Box>
  );

  const getSpeed = () => (
    <Box width={1}>
      <Typography variant="caption">Speed</Typography>
      <Box px={2}>
        <Slider
          color="secondary"
          value={props.config.ledSceneSpeed || 0}
          onChangeCommitted={(
            event: React.SyntheticEvent | Event,
            value: number | number[],
          ) => {
            props.onConfigChange({
              ledSceneSpeed: value as number,
            });
          }}
          step={1}
          valueLabelDisplay="auto"
          marks={[
            { value: 1, label: '1' },
            { value: 50, label: '50' },
            { value: 100, label: '100' },
          ]}
        />
      </Box>
    </Box>
  );

  const getMusicSyncSensitivity = () => (
    <Box width={1}>
      <Typography variant="caption">Music Sync Sensitivity</Typography>
      <Box px={2}>
        <Slider
          color="secondary"
          value={props.config.musicSyncSensitivity || 1}
          onChangeCommitted={(
            event: React.SyntheticEvent | Event,
            value: number | number[],
          ) => {
            props.onConfigChange({
              musicSyncSensitivity: value as number,
            });
          }}
          step={1}
          valueLabelDisplay="auto"
          marks={[
            { value: 1, label: '1' },
            { value: 50, label: '50' },
            { value: 100, label: '100' },
          ]}
        />
      </Box>
    </Box>
  );

  const getSceneMode = () => (
    <Box width={1}>
      <Typography variant="caption">Scene Mode (SELECT LAST)</Typography>
      <Select
        fullWidth
        size="small"
        value={props.config.ledSceneMode || ''}
        onChange={(e: SelectChangeEvent<any>) => {
          props.onConfigChange({
            ledSceneMode: e.target.value,
            bulbColor: '0',
            bulbColorTemp: 0,
            ledPattern: 'custom',
          });
        }}
      >
        <MenuItem value="">&nbsp;</MenuItem>
        {sceneModeOptions.map((o) => (
          <MenuItem value={o.value} key={`scene-mode-${o.key}`}>
            {o.key}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );

  const getLedPattern = () => (
    <Box width={1}>
      <Typography variant="caption">LED Pattern</Typography>
      <Select
        fullWidth
        size="small"
        value={props.config.ledPattern || ''}
        onChange={(e: SelectChangeEvent<any>) => {
          props.onConfigChange({
            ledPattern: e.target.value,
            bulbColor: '0',
            bulbColorTemp: 0,
          });
        }}
      >
        <MenuItem value="">&nbsp;</MenuItem>
        {ledPatternOptions.map((o) => (
          <MenuItem value={o.value} key={`ledPattern-${o.key}`}>
            {o.key}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );

  const getSegmentControl = () => {
    return (
      <Box display="flex" flexDirection="column" rowGap={0.5} width={1}>
        <Typography variant="caption">Segment Control</Typography>
        {props.config.ledSegments.map((segmentValue, i) => {
          return (
            <IroColorPickerTooltip
              key={`${segmentValue}-${i}`}
              label={`Segment ${i + 1}`}
              value={segmentValue}
              onChange={(nextColor: string) => {
                const nextSegments = props.config.ledSegments;
                nextSegments[i] = nextColor.replaceAll('#', '');
                props.onConfigChange({
                  ledSegments: nextSegments,
                  bulbColor: '000000',
                  bulbColorTemp: 0,
                });
              }}
            />
          );
        })}
      </Box>
    );
  };

  const getColorPicker = () => {
    return (
      <Box sx={{ pb: 3 }} alignItems="center" justifyContent="center">
        <IroColorPicker
          value={props.config.bulbColor}
          onChange={(nextColor: string) => {
            props.onConfigChange({
              bulbColor: nextColor.replaceAll('#', ''),
            });
          }}
        />
      </Box>
    );
  };

  return (
    <>
      <ExpandableSection title="RGBIC Strip Config" initiallyOpen>
        <Card sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardHeader subheader="Color Selection" />
          <CardContent
            sx={{
              px: 4,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignContent: 'space-between',
              columnGap: 4,
            }}
          >
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="center"
              sx={{ width: '100%' }}
            >
              {getSegmentControl()}
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              sx={{ width: '100%' }}
            >
              {getColorPicker()}
              {getLedStripLength()}
              {getTemperatureConfig()}
              {getBrightnessConfig()}
              {getSpeed()}
              {getMusicSyncSensitivity()}
              {getSceneMode()}
              {getLedPattern()}
            </Box>
          </CardContent>
        </Card>
      </ExpandableSection>
    </>
  );
};
