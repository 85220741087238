import React from 'react';

import { MoveBrowserClient } from 'move-sdk';
import Init from './Init';

export default function Emulation(props: { moveClient: MoveBrowserClient }) {
  const [init, setInit] = React.useState(false);

  const onInit = () => {
    setInit(false);
  };

  if (!init) {
    return (
      <>
        <Init onClick={onInit} />
      </>
    );
  }

  return <>Emulation not quite as cool as an Emu</>;
}
