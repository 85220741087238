import React from 'react';
import { Alert, Box, Chip, Grid, Typography } from '@mui/material';

import DeviceBridge from './Partials/DeviceBridge';
import ChooseDevice from './Partials/ChooseDevice';
import ChooseNetwork from './Partials/ChooseNetwork';
import Pair from './Partials/Pair';
import GetDeviceInfo from './Partials/GetDeviceInfo';
import GetAccountId from './Partials/GetAccountId';
import Completion from './Partials/Completion';
import Progress from './Partials/Progress';

export default function Pairing() {
  const [bridgeOnline, setBridgeOnline] = React.useState(false);
  const [accountId, setAccountId] = React.useState('');
  const [deviceSSID, setDeviceSSID] = React.useState('');
  const [networkCreds, setNetworkCreds] = React.useState<NetworkCreds>({
    ssid: '',
    password: '',
  });
  const [successfullyPaired, setSuccessfullyPaired] = React.useState(false);
  const [failedToPair, setFailedToPair] = React.useState(false);

  const [pairingResult, setPairingResult] =
    React.useState<ProvisionInstructions>([]);

  const [deviceInfo, setDeviceInfo] = React.useState<DeviceData | undefined>(
    undefined,
  );
  const [connectionType, setConnectionType] = React.useState<
    'AUTO' | 'MANUAL' | undefined
  >(undefined);

  const onBridgeOnline = () => {
    setBridgeOnline(true);
  };
  const onAccountId = (accountId: string) => {
    setAccountId(accountId);
  };
  const onDeviceSSID = (ssid: string) => {
    setDeviceSSID(ssid);
  };
  const onDeviceInfo = (info: DeviceData) => {
    setDeviceInfo(info);
  };
  const onNetworkCreds = (ssid: string, password: string) => {
    setNetworkCreds({ ssid, password });
  };
  const onSuccessfulPair = (instr: ProvisionInstructions) => {
    setSuccessfullyPaired(true);
    setPairingResult(instr);
  };
  const onFailedToPair = (instr: ProvisionInstructions) => {
    setFailedToPair(true);
    setPairingResult(instr);
  };
  const onConnectionType = (nextType: 'AUTO' | 'MANUAL') => {
    setConnectionType(nextType);
  };

  const wrapInBase = (children: JSX.Element): JSX.Element => {
    return (
      <Grid item xs={12} container justifyContent="center" pb={6}>
        <Grid item xs={12} pb={2}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5">&nbsp;Pair a New Device</Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Progress
            deviceBridgeConnected={bridgeOnline}
            accountInfoRetrieved={accountId !== ''}
            connectedToDevice={deviceSSID !== ''}
            deviceInfoRetrieved={!!deviceInfo}
            wifiCredentialsSet={
              networkCreds.ssid !== '' && networkCreds.password !== ''
            }
            devicePaired={successfullyPaired}
          />
        </Grid>
        <Grid item xs={9}>
          {children}
        </Grid>
      </Grid>
    );
  };

  if (!bridgeOnline) {
    return <>{wrapInBase(<DeviceBridge onBridgeOnline={onBridgeOnline} />)}</>;
  }

  if (accountId === '') {
    return <>{wrapInBase(<GetAccountId onAccountId={onAccountId} />)}</>;
  }

  if (deviceSSID === '') {
    return <>{wrapInBase(<ChooseDevice onDeviceSSID={onDeviceSSID} />)}</>;
  }

  if (!deviceInfo) {
    return (
      <>
        {wrapInBase(
          <GetDeviceInfo deviceSsid={deviceSSID} onDeviceInfo={onDeviceInfo} />,
        )}
      </>
    );
  }

  if (deviceInfo && (networkCreds.ssid === '' || networkCreds.password == '')) {
    return (
      <>
        {wrapInBase(
          <ChooseNetwork
            knownNetworks={
              deviceInfo.wifiNetworks ? deviceInfo.wifiNetworks.values : []
            }
            onNetworkCreds={onNetworkCreds}
          />,
        )}
      </>
    );
  }

  if (!successfullyPaired && !failedToPair) {
    return (
      <>
        {wrapInBase(
          <Pair
            onSuccessfulPair={onSuccessfulPair}
            onFailedToPair={onFailedToPair}
            deviceSSID={deviceSSID}
            networkCreds={networkCreds}
            deviceData={deviceInfo}
            accountId={accountId}
          />,
        )}
      </>
    );
  }

  if (successfullyPaired || failedToPair) {
    return (
      <>
        {wrapInBase(
          <Completion success={successfullyPaired} history={pairingResult} />,
        )}
      </>
    );
  }

  return <>{wrapInBase(<>Encountered unhandled state</>)}</>;
}
