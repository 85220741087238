import React from 'react';
import { Box, Button, Typography } from '@mui/material';

import { MoveBrowserClient } from 'move-sdk';

import ExpandableCard from '../../../../../Components/ExpandableCard';
import LogsReaderDialog from '../../../Partials/ForceUpgrade/ForceUpgradeDialog';

export default function DeviceInfo(props: {
  config: DeviceConfig | undefined;
  moveClient: MoveBrowserClient;
}) {
  const [extraInfo, setExtraInfo] = React.useState(false);

  const [logReaderOpen, setLogReaderOpen] = React.useState(false);

  return (
    <ExpandableCard title="Info" smallMargin initiallyOpen>
      <Box display="flex" flexDirection="column">
        {props.config && (
          <>
            <Button
              variant="text"
              fullWidth
              sx={{
                color: '#000',
                textAlign: 'left',
                justifyContent: 'unset',
                padding: 0,
              }}
              onClick={() => {
                setExtraInfo(!extraInfo);
              }}
            >
              <Typography variant="body2">
                Name: <b>{props.config.name}</b>
              </Typography>
            </Button>
            <Button
              variant="text"
              fullWidth
              sx={{
                color: '#000',
                textAlign: 'left',
                justifyContent: 'unset',
                padding: 0,
              }}
              onClick={() => {
                setLogReaderOpen(true);
              }}
            >
              <Typography variant="body2">
                Device ID: <b>{props.config.deviceId}</b>
              </Typography>
            </Button>
            <Typography variant="body2">
              Model: <b>{props.config.model}</b>
            </Typography>
            {Object.keys(props.config)
              .filter((ps) => ps.includes('powerState'))
              .map((key, idx) => {
                return (
                  <Typography key={key} variant="body2">
                    {`Power State${idx ? `-${idx}` : ''}`}:{' '}
                    {props.config![key as keyof DeviceConfig]}
                  </Typography>
                );
              })}
            <Typography variant="body2">
              Schedule: <b>{JSON.stringify(props.config.schedule)}</b>
            </Typography>
            {props.config.createdAt && (
              <Typography variant="body2">
                Created At: <b>{JSON.stringify(props.config.createdAt)}</b>
              </Typography>
            )}

            {props.config.lastPairedAt && (
              <Typography variant="body2">
                Last Paired At:{' '}
                <b>{JSON.stringify(props.config.lastPairedAt)}</b>
              </Typography>
            )}
            {extraInfo && (
              <>
                {props.config.state !== undefined && (
                  <Typography variant="body2">
                    Last State: <b>{props.config.state}</b>
                  </Typography>
                )}
              </>
            )}
          </>
        )}
      </Box>
      <LogsReaderDialog
        open={logReaderOpen}
        onClose={() => {
          setLogReaderOpen(false);
        }}
        moveClient={props.moveClient}
        deviceId={props.config?.deviceId || ''}
      />
    </ExpandableCard>
  );
}
