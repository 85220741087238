import React from 'react';
import { Box, Button, Card } from '@mui/material';

export default function Init(props: { onClick: VoidFunction }) {
  return (
    <>
      <Box display="flex" flexDirection="row" sx={{ width: '100%' }}>
        <Card sx={{ p: 4 }}>
          <Button variant="contained" fullWidth>
            Start
          </Button>
        </Card>
      </Box>
    </>
  );
}
