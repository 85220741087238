let servers: ServerData[] = [
  {
    label: 'Localhost',
    value: 'local',
    path: 'https://dev.api.pepperos.io/authentication/byEmail',
    wsPath: 'wss://dev.move.pepperos.io/ws',
  },
  {
    label: 'Dev',
    value: 'dev',
    path: 'https://dev.api.pepperos.io/authentication/byEmail',
    wsPath: 'wss://dev.move.pepperos.io/ws',
  },
  {
    label: 'Stage',
    value: 'staging',
    path: 'https://staging.api.pepperos.io/authentication/byEmail',
    wsPath: 'wss://stage.move.pepperos.io/ws',
  },
  {
    label: 'UAT',
    value: 'uat',
    path: 'https://uat.api.pepperos.io/authentication/byEmail',
    wsPath: 'wss://uat.move.pepperos.io/ws',
  },
  {
    label: 'Prod',
    value: 'prod',
    path: 'https://api.pepperos.io/authentication/byEmail',
    wsPath: 'wss://prod.move.pepperos.io/ws',
  },
];

export const getServer = () => {
  const { hostname } = window.location;
  switch (hostname) {
    case 'localhost':
      return 'local';
    case 'dev.move-validation.pepperos.io':
      return 'dev';
    case 'staging.move-validation.pepperos.io':
      return 'staging';
    case 'uat.move-validation.pepperos.io':
      return 'uat';
    case 'move-validation.pepperos.io':
      return 'prod';
    default:
      return 'dev'
  }
}

export default servers;
