import CryptoJS from 'crypto-js';

const get = (
  session: Session,
  deviceData: DeviceData,
  accountId: string,
  networkCreds: NetworkCreds,
): ProvisionInstructions => {
  if (deviceData.deviceInfo.macID) {
    return getSecure(session, deviceData, accountId, networkCreds);
  }
  return getInsecure(session, deviceData, accountId, networkCreds);
};

const getSecure = (
  session: Session,
  deviceData: DeviceData,
  accountId: string,
  networkCreds: NetworkCreds,
): ProvisionInstructions => {
  const wifiData = {
    ssid: networkCreds.ssid,
    deviceID: deviceData.deviceInfo.value,
    password: networkCreds.password,
  };
  console.log(wifiData);
  const wifiDataStr = JSON.stringify(wifiData);
  const macID = deviceData.deviceInfo.macID || '';
  let randomKey = '';
  for (let i = 0; i < 64; i++) {
    randomKey = `${randomKey}${((Math.random() * 0xf) << 0).toString(16)}`;
  }
  const aes_iv = deviceData.deviceInfo.value.slice(-16);
  const keyStr =
    randomKey.substring(0, 8) +
    macID.substring(10, 12) +
    randomKey.substring(8, 16) +
    macID.substring(8, 10) +
    randomKey.substring(16, 24) +
    macID.substring(6, 8) +
    randomKey.substring(24, 32) +
    macID.substring(4, 6) +
    randomKey.substring(44, 52) +
    macID.substring(2, 4) +
    randomKey.substring(52, 60) +
    macID.substring(0, 2) +
    randomKey.substring(60, 64);
  const iv = CryptoJS.enc.Utf8.parse(aes_iv);
  const key = CryptoJS.enc.Hex.parse(keyStr);
  const cipherText = CryptoJS.AES.encrypt(wifiDataStr, key, { iv: iv });
  const encrypted = CryptoJS.format.OpenSSL.stringify(cipherText);

  const ir: ProvisionInstructions = [
    {
      name: 'Get Device ID',
      cmd: { req: 'get device id' },
    },
    {
      name: 'Set Account ID',
      cmd: { req: 'set account id', value: accountId, data: randomKey },
    },
    {
      name: 'Set Move Server',
      cmd: { req: 'set server', value: session.userData.serverData.wsPath },
    },
    {
      name: 'Set Wifi',
      cmd: { req: 'set wifi', data: encrypted },
    },
  ];
  return ir;
};

const getInsecure = (
  session: Session,
  deviceData: DeviceData,
  accountId: string,
  networkCreds: NetworkCreds,
): ProvisionInstructions => {
  const shiftCipher = 4;
  const formattedSsid = asciiShift(networkCreds.ssid, shiftCipher);
  const formattedPassword = asciiShift(networkCreds.password, shiftCipher);

  const ir: ProvisionInstructions = [
    {
      name: 'Set Account ID',
      cmd: { req: 'set account id', value: accountId },
    },
    {
      name: 'Set Move Server',
      cmd: { req: 'set server', value: session.userData.serverData.wsPath },
    },
    {
      name: 'Set Vendor',
      cmd: { req: 'set vendor', value: session.userData.vendor },
    },
    {
      name: 'Set Name',
      cmd: { req: 'set name', value: 'new-device' },
    },
    {
      name: 'Set Wifi',
      cmd: {
        req: 'set wifi',
        ssid: formattedSsid,
        password: formattedPassword,
      },
    },
  ];
  return ir;
};

function asciiShift(str: any, shift: any) {
  return str
    .split('')
    .map(function (c: any) {
      return String.fromCharCode(c.charCodeAt(0) + shift);
    })
    .join('');
}

const pairingInstructions = {
  get,
};

export default pairingInstructions;
