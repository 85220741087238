import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ContentPane from './ContentPane';
import Header from './Header';
import BreadCrumb from './Breadcrumb';

export default function Base(props: {
  allowUnauthenticated: boolean;
  children: JSX.Element;
}) {
  const session = useSelector((state: PepperState) => state.session);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!props.allowUnauthenticated && !session.authenticated) {
      navigate('/login');
    }
  }, [props.allowUnauthenticated, session.authenticated, navigate]);

  return (
    <>
      <Header />
      <BreadCrumb />
      <ContentPane>{props.children}</ContentPane>
    </>
  );
}
