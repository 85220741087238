import React from 'react';
import { DialogActions, Button, TextField, Box } from '@mui/material';

export default function CommandDialogForm(props: {
  onClose: VoidFunction;
  onCommands: (cmds: string) => void;
}) {
  const [value, setValue] = React.useState('');
  return (
    <>
      <Box display="flex" flexDirection="column" rowGap={2}>
        <TextField
          value={value}
          onChange={(event) => setValue(event.target.value)}
          size="small"
          multiline
          rows={6}
        />
        <Button variant="contained" onClick={() => props.onCommands(value)}>
          Submit
        </Button>
      </Box>

      <DialogActions>
        <Button onClick={props.onClose}>Close</Button>
      </DialogActions>
    </>
  );
}
