import platformServices from '../../modules/platformServices';
import { Dispatch } from '../store';

export const SESSION_AUTHENTICATE = 'session/AUTHENTICATE';
export const SESSION_DESTROY = 'session/DESTROY';
export const SESSION_HYDRATE = 'session/HYDRATE';
export const SESSION_FETCH_BEGIN = 'session/FETCH_BEGIN';
export const SESSION_FETCH_END = 'session/FETCH_END';
export const SESSION_FETCH_ERROR = 'session/FETCH_ERROR';

export function hydrateSession() {
  return (dispatch: Dispatch) => {
    dispatch({ type: SESSION_HYDRATE, payload: {} });
  };
}

export function authenticate(
  server: string,
  username: string,
  password: string,
  vendor: string,
) {
  return (dispatch: Dispatch): Promise<UserData> => {
    dispatch({ type: SESSION_FETCH_BEGIN });
    return platformServices
      .auth(server, username, password, vendor)
      .then((userData: UserData) => {
        dispatch({ type: SESSION_AUTHENTICATE, payload: userData });
        return Promise.resolve(userData);
      })
      .catch(() => {
        dispatch({ type: SESSION_FETCH_ERROR });
        return Promise.reject(null);
      });
  };
}

export function destroy() {
  return (dispatch: Dispatch): Promise<boolean> => {
    dispatch({ type: SESSION_DESTROY });
    return Promise.resolve(true);
  };
}
