import React from 'react';
import { Box, Typography } from '@mui/material';

import ExpandableCard from '../../../../../Components/ExpandableCard';

export default function ChimeInfoControl(props: {
  config: ChimeConfig | undefined;
}) {
  return (
    <ExpandableCard title="Chime Info" smallMargin initiallyOpen>
      <Box display="flex" flexDirection="column">
        {props.config && (
          <>
            <Typography variant="body2">
              Name: <b>{props.config.name}</b>
            </Typography>
            <Typography variant="body2">
              Device ID: <b>{props.config.deviceId}</b>
            </Typography>
            <Typography variant="body2">
              Model: <b>{props.config.model}</b>
            </Typography>
            <Typography variant="body2">
              Firmware Version: <b>{props.config.firmwareVersion}</b>
            </Typography>
            <Typography variant="body2">
              Online:{' '}
              <b>{props.config.powerStateOn !== 0 ? 'true' : 'false'}</b>
            </Typography>
            <Typography variant="body2">
              Connected Doorbells:{' '}
              <b>{JSON.stringify(props.config.doorBells)}</b>
            </Typography>
            <Typography variant="body2">
              Schedule: <b>{JSON.stringify(props.config.schedule)}</b>
            </Typography>
          </>
        )}
      </Box>
    </ExpandableCard>
  );
}
