import React, { useState } from 'react';
import {
  Box,
  Button,
  ButtonProps, Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import ExpandableCard from '../../../../../Components/ExpandableCard';

import { MoveBrowserClient } from 'move-sdk';

import deviceConfig from '../../../modules/deviceConfig';

const buttonProps: ButtonProps = {
  size: 'small',
  color: 'secondary',
  variant: 'outlined',
  fullWidth: true,
};

export default function DeviceControl(props: {
  deviceId: string;
  moveClient: MoveBrowserClient;
  overrideLabel?: string;
  config: DeviceConfig;
  onConfigChange?: (key: any, nextValue: any, moveData?: any) => void;
}) {
  const [config, setConfig] = useState(props.config);
  const [selectedOutlet, setSelectedOutlet] = useState<string>("power");
  const configChangeCB = React.useCallback(() => {
    setConfig(props.config);
  }, [setConfig, props.config]);
  React.useEffect(configChangeCB, [configChangeCB, props.config]);

  const onEvent = (eventType: string) => {
    props.moveClient.CreateEvent(props.deviceId, eventType);
  };

  const handleUpdatePowerState = () => {
    // If an outlet gets selected in the dropdown, it has a suffix (i.e. powerStateOn-1). If not, it is 'powerStateOn'
    let powerStateKey: string = selectedOutlet !== 'power' ? `powerStateOn-${selectedOutlet}` : 'powerStateOn';
    let powerStateNextValue: number =
      config[powerStateKey as keyof DeviceConfig] === 0 ? 1 : 0;

    onConfigUpdate(
      powerStateKey as keyof DeviceConfig,
      powerStateNextValue,
      deviceConfig.configUpdates.powerStateToggle(
        powerStateNextValue,
        powerStateKey,
      ),
    );
  };

  const onConfigUpdate = (
    key: keyof DeviceConfig,
    nextValue: any,
    moveData: any,
  ) => {
    const nextConfig = {
      ...config,
      ...moveData,
    };
    setConfig(nextConfig);
    if (props.onConfigChange) {
      props.onConfigChange(key, nextValue, moveData);
    }
    props.moveClient.sendToDevice(props.deviceId, 'updateConfig', moveData);
  };

  return (
    <ExpandableCard title={props.overrideLabel || 'Control'} initiallyOpen>
      <Box display="flex" flexDirection="column" rowGap={1}>
        <Box display="flex" flexDirection="row" columnGap={1}>
          <Button
            {...buttonProps}
            onClick={() => {
              onEvent(deviceConfig.events.reboot);
            }}
          >
            Reboot
          </Button>
          <Button
            {...buttonProps}
            onClick={() => {
              onEvent(deviceConfig.events.reset);
            }}
          >
            Reset
          </Button>
        </Box>

        <Box display="flex" flexDirection="row" columnGap={2}>
          <Button
            {...buttonProps}
            onClick={() => {
              onEvent(deviceConfig.events.ota);
            }}
          >
            Firmware Update (OTA)
          </Button>
        </Box>

        <Divider />
        <Box display="flex" flexDirection="column" rowGap={1}>
          <Box display="flex" flexDirection="row" columnGap={1}>
            <Select
              onChange={(e: SelectChangeEvent) =>
                setSelectedOutlet(e.target.value)
              }
              value={selectedOutlet}
              fullWidth={true}
              size="small"
            >
              <MenuItem selected value="power">Power</MenuItem>
              {/* @TODO: hardcoding 10 here for the number of outlets on the plug. In the future this may need */}
              {/* @TODO: to be updated to a variable if we have a device with less than 10 outlets */}
              {[...Array(11)].map((e, i) => {
                if (i === 0) return null; // `powerStateOn-0` is not a possible option, so just removing it for now
                return (
                  <MenuItem value={(i).toString()} key={i}>
                    Outlet {i}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box display="flex" flexDirection="row" columnGap={2}>
            <Button {...buttonProps} onClick={handleUpdatePowerState}>
              Toggle Power State
            </Button>
          </Box>
        </Box>
      </Box>
    </ExpandableCard>
  );
}
