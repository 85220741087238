import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { Link } from 'react-router-dom';

const GridBar = styled(Grid)`
  background-color: #151a0b;
  box-shadow: 0px 1px 10px #888888;
  color: #ffffff;
  padding: 2rem 0rem;
`;

export default function Header() {
  return (
    <GridBar container justifyContent="center" alignItems="center">
      <Grid
        item
        xs={10}
        container
        direction="row"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Link to="/device">
            <img src="/pepper-logo.292378a4.svg" alt="Pepper" />
          </Link>
        </Grid>
        <Grid item>
          <Typography variant="h5">Move</Typography>
        </Grid>
      </Grid>
    </GridBar>
  );
}
