import React from 'react';
import {
  Alert,
  Card,
  CardContent,
  Button,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  FormHelperText,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCookie from 'react-use-cookie';
import { getServer } from '../../modules/servers';
import sessionHooks from '../../redux/hooks/session';

const defaultFormData = {
  username: '',
  password: '',
  vendor: 'pepper',
  server: getServer(),
};

const defaultFormError = {
  username: '',
  password: '',
  vendor: '',
  server: '',
};

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [storedUserCreds, setStoredUserCreds] = useCookie('token', '');

  const [retrieved, setRetrieved] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [formError, setFormError] =
    React.useState<typeof defaultFormError>(defaultFormError);
  const [formData, setFormData] =
    React.useState<typeof defaultFormData>(defaultFormData);

  React.useEffect(() => {
    if (storedUserCreds !== '' && !retrieved) {
      const split = storedUserCreds.split(':-:');
      if (split.length === 4) {
        if (formData.username !== split[0]) {
          setFormData({
            ...formData,
            username: split[0],
            password: split[1],
            vendor: split[2],
            server: split[3],
          });
        }
      }
    }
    setRetrieved(true);
  }, [storedUserCreds, setFormData, formData]);

  const onChangeTextInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormError(defaultFormError);
    setFormData({
      ...formData,
      [event.target.id]: event.target.value,
    });
  };

  const valid = (): boolean => {
    const errors = { ...defaultFormError };
    let hadError = false;
    if (formData.username === '') {
      errors.username = 'username is required';
      hadError = true;
    }
    if (formData.password === '') {
      errors.password = 'password is required';
      hadError = true;
    }
    if (hadError) {
      setFormError(errors);
    }
    return !hadError;
  };

  const onSubmit = () => {
    if (!valid()) {
      console.log('invalid');
      return;
    }
    setLoading(true);
    sessionHooks
      .map(dispatch)
      .authenticate(
        getServer(),
        formData.username,
        formData.password,
        formData.vendor,
      )
      .then((res) => {
        setStoredUserCreds(
          `${formData.username}:-:${formData.password}:-:${formData.vendor}:-:${formData.server}`,
        );
        navigate('/device');
      })
      .catch((err) => {
        setFormError({
          ...defaultFormError,
          server: 'please try again',
        });
        setLoading(false);
      });
  };

  return (
    <Grid
      item
      spacing={2}
      xs={12}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={8}>
        {getServer() === 'dev' ? (
          <Alert severity="error">
            You are currently on a{' '}
            <span style={{ fontWeight: 'bold' }}>development environment</span>{' '}
            of this website, and your production credentials will not work. If
            you are looking for the production site, bookmark&nbsp;
            <a
              style={{ textDecoration: 'underline', fontWeight: 'bold' }}
              href="https://move-validation.pepperos.io"
            >
              this link
            </a>{' '}
            for future usage. For UAT,{' '}
            <a
              style={{ textDecoration: 'underline', fontWeight: 'bold' }}
              href="https://uat.move-validation.pepperos.io"
            >
              this link
            </a>{' '}
            will take you to the correct application.
          </Alert>
        ) : null}
        <Typography variant="h5">Login</Typography>
      </Grid>
      <Grid item xs={8}>
        <Card style={{ padding: '1rem 0' }}>
          <CardContent>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="center"
              spacing={2}
            >
              {formError.server !== '' && (
                <Grid item xs={11}>
                  <Typography variant="body1" color="error">
                    {formError.server}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={11}>
                <TextField
                  id="username"
                  label="Username"
                  variant="standard"
                  fullWidth
                  value={formData.username}
                  onChange={onChangeTextInput}
                  disabled={loading}
                  error={formError.username !== ''}
                />
                {formError.username !== '' && (
                  <FormHelperText error>{formError.username}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={11}>
                <TextField
                  id="password"
                  label="Password"
                  variant="standard"
                  type="password"
                  fullWidth
                  value={formData.password}
                  onChange={onChangeTextInput}
                  disabled={loading}
                  error={formError.password !== ''}
                />
                {formError.password !== '' && (
                  <FormHelperText error>{formError.password}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={11}>
                <TextField
                  id="vendor"
                  label="vendor"
                  variant="standard"
                  fullWidth
                  value={formData.vendor}
                  onChange={onChangeTextInput}
                  disabled={loading}
                  error={formError.vendor !== ''}
                />
              </Grid>
              {!loading && (
                <Grid item xs={11}>
                  <Button variant="contained" fullWidth onClick={onSubmit}>
                    Login
                  </Button>
                </Grid>
              )}
              {loading && (
                <Grid item xs={11} style={{ textAlign: 'center' }}>
                  <CircularProgress disableShrink />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
