import React from 'react';
import { Alert } from '@mui/material';

import ExpandableCard from '../../../../../Components/ExpandableCard';

export default function IndoorSmartPlugConfig(props: {}) {
  return (
    <ExpandableCard title="Indoor SmartPlug Config" initiallyOpen smallMargin>
      <Alert severity="info">
        No additional config parameters for this device.
      </Alert>
    </ExpandableCard>
  );
}
