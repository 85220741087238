import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from '../reducers/index';

let storeEnhancers;
let middleware;
if (process.env.NODE_ENV === 'development') {
  storeEnhancers =
    (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  middleware = applyMiddleware(logger, thunk);
} else {
  storeEnhancers = compose;
  middleware = applyMiddleware(thunk);
}

const store = createStore(rootReducer, storeEnhancers(middleware));

export type Dispatch = typeof store.dispatch;

export default store;
