import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/system';

const ContentGrid = styled(Grid)`
  margin-top: 2rem;
`;

export default function ContentPane(props: { children: JSX.Element }) {
  return (
    <ContentGrid container justifyContent="center" alignItems="center">
      <Grid container item xs={10}>
        {props.children}
      </Grid>
    </ContentGrid>
  );
}
