import React from 'react';
import { v4 } from 'uuid';
import { Box, Grid } from '@mui/material';
import BlurOnIcon from '@mui/icons-material/BlurOn';

import LoadingCard from '../../../../../Components/LoadingCard';
import ExtendedDeviceInfo from './Partials/ExtendedInfo';
import DeviceInfo from './Partials/DeviceInfo';
import DeviceControl from './Partials/DeviceControl';
import DeviceEvents from './Partials/DeviceEvents';


import { MoveBrowserClient } from 'move-sdk';
import CommonHeader from '../../../Partials/CommonHeader';
import CommandPanel from '../../../Partials/command-panel';

export interface ConfigProps<T extends DeviceConfig> {
  device: MoveDevice;
  config: T;
  onConfigChange: (moveData: Partial<T>) => void;
}

export interface PepperDeviceTemplateProps<T extends DeviceConfig> {
  moveClient: MoveBrowserClient;
  onChangeDevice: () => void;
  device: MoveDevice;
  Config: React.FC<ConfigProps<T>>;
  transformInitialConfig?: (config: T) => T;
}

export default function PepperDeviceTemplate<T extends DeviceConfig>(
  props: PepperDeviceTemplateProps<T>,
) {
  const [deviceConfig, setDeviceConfig] = React.useState<T | undefined>(
    undefined,
  );
  const [events, setEvents] = React.useState<MoveEvent[]>([]);

  const onConfigChange = (key: keyof RGBStripConfig, nextValue: any) => {
    if (deviceConfig) {
      const nextConfig: T = {
        ...deviceConfig,
        [key]: nextValue,
      };
      setDeviceConfig(nextConfig);
    }
  };

  const onRequestConfigChange = (moveData: Partial<T>) => {
    props.moveClient.sendToDevice(
      props.device.addresses[0].address,
      'updateConfig',
      moveData,
    );
  };

  const handleNewEvent = (data: any) => {
    let nextEvents = events;
    try {
      if (data.event.parent === props.device.addresses[0].address) {
        nextEvents = [data.event, ...events];
      }
    } catch {}
    setEvents(nextEvents);
  };

  const handleConfigUpdate = (newConfig: any) => {
    if (!newConfig) {
      return;
    }
    // we only get the full config on the first update
    if (newConfig.parms) {
      newConfig.parms.deviceId = newConfig.device_id;

      let parms = newConfig.parms;
      if (props.transformInitialConfig) {
        parms = props.transformInitialConfig(parms);
      }

      setDeviceConfig({
        ...parms,
        createdAt: newConfig.created,
        lastPairedAt: newConfig.lastPairedAt,
        state: newConfig.state,
      });
      return;
    }
    setDeviceConfig({
      ...deviceConfig,
      ...newConfig,
    });
    const newEvent: MoveEvent = {
      account: '',
      created: new Date().toString(),
      id: v4(),
      media_key_1: '',
      name: '',
      parent: '',
      type: `updateConfig - ${JSON.stringify(newConfig)}`,
      updated: '',
    };
    setEvents([newEvent, ...events]);
  };

  props.moveClient.onConfigUpdate = handleConfigUpdate;
  props.moveClient.onNewEvent = handleNewEvent;
  props.moveClient.onNotification = handleNewEvent;

  const deviceConfigCB = React.useCallback(() => {
    if (!deviceConfig) {
      let deviceId = '';
      if (props.device.addresses.length !== 0) {
        deviceId = props.device.addresses[0].address;
      }
      if (deviceId !== '') {
        props.moveClient.onConfigUpdate = handleConfigUpdate;
        props.moveClient.getConfig(deviceId);
      } else {
        console.error('could not determine device id');
      }
    }
  }, [deviceConfig, setDeviceConfig, props.device, props.moveClient]);
  React.useEffect(deviceConfigCB, [deviceConfigCB, props.device]);

  const renderDevice = () => {
    return (
      <Grid item xs={12} container columnGap={4}>
        <Grid item xs={4}>
          <Box display="flex" flexDirection="column" rowGap={4}>
            <DeviceInfo config={deviceConfig} moveClient={props.moveClient} />
            <CommandPanel
              deviceId={props.device.addresses[0].address}
              moveClient={props.moveClient}
              onConfigChange={onConfigChange}
            />
            <DeviceControl
              deviceId={props.device.addresses[0].address}
              moveClient={props.moveClient}
              config={deviceConfig!}
              onConfigChange={onConfigChange}
            />
            <ExtendedDeviceInfo
              config={deviceConfig}
              moveClient={props.moveClient}
            />
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Box display="flex" flexDirection="column" rowGap={4}>
            <props.Config
              device={props.device}
              config={deviceConfig!}
              onConfigChange={onRequestConfigChange}
            />
            <DeviceEvents events={events} moveClient={props.moveClient} />
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid item xs={12} container justifyContent="center" sx={{ pb: 4 }}>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column" rowGap={2}>
          <CommonHeader
            Icon={<BlurOnIcon fontSize="large" />}
            viewTitle={props.device.label}
            onChangeDevice={props.onChangeDevice}
          />
          {!deviceConfig && <LoadingCard msg="Getting Device Config" />}
          {deviceConfig && renderDevice()}
        </Box>
      </Grid>
    </Grid>
  );
}
