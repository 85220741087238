import React from 'react';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import { useNavigate } from 'react-router-dom';

import deviceProperties from '../module/deviceProperties';
import bridge from '../../../modules/bridge';
import ExpandableError from '../../../Components/ExpandableError';

export default function GetDeviceInfo(props: {
  deviceSsid: string;
  onDeviceInfo: (info: DeviceData) => void;
}) {
  const navigate = useNavigate();
  const [deviceInfoError, setDeviceInfoError] = React.useState<
    any | undefined
  >();
  const [includeWifiInfo, setIncludeWifiInfo] = React.useState(true);
  const [selected, setSelected] = React.useState(false);

  const getDeviceInfo = () => {
    setDeviceInfoError(undefined);
    bridge
      .getDeviceInfo(
        props.deviceSsid,
        deviceProperties.defaultHost,
        deviceProperties.defaultPort,
        includeWifiInfo,
      )
      .then((res) => {
        props.onDeviceInfo(res);
      })
      .catch((err) => {
        console.error(err);
        if (err && err.error && err.error.includes('access-point-error')) {
          console.log('ape', err);
        }
        setDeviceInfoError(err);
      });
  };

  if (!selected) {
    return (
      <Grid item xs={12} container justifyContent="center">
        <Box display="flex" flexDirection="column" width={1}>
          <Card sx={{ px: 4, py: 2 }}>
            <Box display="flex" flexDirection="column" rowGap={2} width={1}>
              <Typography variant="h6">Get Device Info</Typography>
              <Box display="flex" flexDirection="column">
                <FormControlLabel
                  control={
                    <Switch
                      checked={includeWifiInfo}
                      value={includeWifiInfo}
                      onChange={() => {
                        setIncludeWifiInfo(!includeWifiInfo);
                      }}
                    />
                  }
                  label="Include WiFi scan"
                />
                <Typography variant="caption">
                  If selected this will ask the device for a list of wifi
                  networks that are within range. Otherwise you will be prompted
                  to enter the ssid manually.
                </Typography>
              </Box>

              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  setSelected(true);
                  getDeviceInfo();
                }}
              >
                Continue
              </Button>
            </Box>
          </Card>
        </Box>
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        <Card sx={{ p: 4 }}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            rowGap={2}
          >
            {!deviceInfoError && selected && (
              <>
                <CircularProgress />
                <Typography variant="caption">Getting Device Info</Typography>
                <Typography variant="caption">
                  This could take a few seconds. Do not exit out of this page or
                  click any other links.
                </Typography>
              </>
            )}
            {deviceInfoError && selected && (
              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  columnGap={1}
                  pb={2}
                >
                  <ErrorTwoToneIcon />
                  <Typography variant="h6">...Something Went Wrong</Typography>
                </Box>
                <Typography variant="body2" pb={2}>
                  Restart the device and ensure that it is in pairing mode. Then
                  please try again. If issues persist, please share the error
                  below.
                </Typography>
                <Grid item xs={12} pb={2}>
                  <ExpandableError
                    title="Reported Device Info Error"
                    initiallyOpen
                  >
                    {JSON.stringify(deviceInfoError)}
                  </ExpandableError>
                </Grid>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    navigate('/device');
                  }}
                >
                  Back
                </Button>
              </Grid>
            )}
          </Box>
        </Card>
      </Box>
    </Grid>
  );
}
