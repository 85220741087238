import React from 'react';
import {
  Box,
  Button,
  Typography,
  useTheme,
  lighten,
  CircularProgress,
} from '@mui/material';

import ExpandableCard from '../../../../../Components/ExpandableCard';

import { MoveBrowserClient } from 'move-sdk';
import LogsReaderDialog from '../../../Partials/ForceUpgrade/ForceUpgradeDialog';
import { Mic, MicOff } from '@mui/icons-material';

export default function StreamingControl(props: {
  device: MoveDevice;
  moveClient: MoveBrowserClient;
  channel: number | undefined;
}) {
  const theme = useTheme();

  const [callId, setCallId] = React.useState('');

  const [onCall, setOnCall] = React.useState(false);
  const [callLoading, setCallLoading] = React.useState(false);
  const [substream, setSubstream] = React.useState(false);
  const [stream, setStream] = React.useState<MediaStream | undefined>();
  const videoRef = React.useRef<HTMLVideoElement>(null);

  React.useEffect(() => {
    if (videoRef && videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  const placeCall = (channel: number | undefined, substream: boolean) => {
    const address = props.device.addresses.find(
      (a) => a.addressType === 'camera' || a.addressType === 'iot_device',
    );
    if (address) {
      setOnCall(true);
      setCallLoading(true);

      props.moveClient.onCallId = (id: string) => {
        setCallId(id);
      };

      props.moveClient.onVideo = (callId: string, cid: string, event: any) => {
        setCallLoading(false);
        setStream(event);
      };

      props.moveClient.onHangup = (callId: string) => {
        setOnCall(false);
        setStream(undefined);
        setCallLoading(false);
      };

      props.moveClient.placeCall(
        address?.address,
        'TN',
        false,
        channel,
        substream,
        (data: any) => {},
      );
    }
  };

  const endCall = () => {
    props.moveClient.hangupCall(callId, () => {});
    setOnCall(false);
    setStream(undefined);
    setCallLoading(false);
  };

  const [isMuted, setIsMuted] = React.useState(false);
  const toggleMicrophone = () => {
    props.moveClient.mute(!isMuted);
    setIsMuted(!isMuted);
  };

  const [isFullIce, setIsFullIce] = React.useState(props.moveClient.turnOnly);
  const toggleFullIce = () => {
    props.moveClient.turnOnly = !isFullIce;
    setIsFullIce(!isFullIce);
  };

  const [logsOpen, setLogsOpen] = React.useState(false);

  return (
    <>
      <ExpandableCard title="Live Streaming" initiallyOpen smallMargin>
        <Box display="flex" flexDirection="column" rowGap={2}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="space-between"
          >
            <Button
              size="small"
              variant="contained"
              onClick={() =>
                onCall ? endCall() : placeCall(props.channel, substream)
              }
            >
              {onCall ? 'End' : 'Live Video'}
            </Button>
            <Box display="flex" flexDirection="row" columnGap={1}>
              <Button
                size="small"
                variant={substream ? 'contained' : 'outlined'}
                color="secondary"
                onClick={() => setSubstream(!substream)}
              >
                {substream ? 'Turn off Substream' : 'Turn on Substream'}
              </Button>
              <Button
                size="small"
                variant={isMuted ? 'contained' : 'outlined'}
                color="secondary"
                onClick={() => toggleMicrophone()}
              >
                {isMuted ? <MicOff color="error" /> : <Mic />}
                {isMuted ? 'Unmute Microphone' : 'Mute Microphone'}
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => toggleFullIce()}
              >
                {isFullIce ? 'Turn Only' : 'Full Ice'}
              </Button>
              {/* <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => alert('todo')}
              >
                Full Screen
              </Button> */}
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() =>
                  window.open(
                    'chrome://webrtc-internals',
                    '_blank',
                    'nodeIntegration=no',
                  )
                }
              >
                WebRTC
              </Button>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            {!onCall && (
              <Box width={1} display="flex" justifyContent="center" p={2}>
                <Box
                  width={1}
                  sx={{
                    p: 6,
                    minHeight: '320px',
                    borderRadius: '3px',
                    border: `1px solid ${lighten(
                      theme.palette.secondary.main,
                      0.6,
                    )}`,
                    background: lighten(theme.palette.secondary.light, 0.8),
                  }}
                >
                  <Typography
                    component={Box}
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    variant="caption"
                    onClick={() => {
                      setLogsOpen(true);
                    }}
                  >
                    Device Thumbnail
                  </Typography>
                </Box>
              </Box>
            )}
            {onCall && callLoading && (
              <Box width={1} p={6}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  rowGap={2}
                >
                  <CircularProgress />
                  <Typography variant="caption">
                    Connecting to Device
                  </Typography>
                </Box>
              </Box>
            )}
            {onCall && !callLoading && stream && videoRef && (
              <Box display="flex" width={1} sx={{ minHeight: '5rem' }}>
                <video
                  ref={videoRef}
                  autoPlay
                  width="100%"
                  onPlaying={() => {
                    console.log('video playing');
                  }}
                ></video>
              </Box>
            )}
          </Box>
        </Box>
      </ExpandableCard>
      <LogsReaderDialog
        open={logsOpen}
        onClose={() => {
          setLogsOpen(false);
        }}
        moveClient={props.moveClient}
        deviceId={props.device.addresses[0].address}
        isCamera
      />
    </>
  );
}
