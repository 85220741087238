import React from 'react';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import useCookie from 'react-use-cookie';

import { MoveBrowserClient } from 'move-sdk';

export default function LogsReaderDialog(props: {
  open: boolean;
  onClose: VoidFunction;
  moveClient: MoveBrowserClient;
  deviceId: string;
  isCamera?: boolean;
}) {
  const [sent, setSent] = React.useState(false);
  const [send, setSend] = React.useState(false);
  const [storedFirmwareUrl, setStoredFirmwareUrl] = useCookie(
    'firmwareurl',
    '',
  );
  const [firmwareURL, setFirmwareURL] = React.useState(storedFirmwareUrl);
  const [deviceSetting, setDeviceSetting] = React.useState('');
  const [deviceSettingValue, setDeviceSettingValue] = React.useState('');
  const [mac, setMACAddress] = React.useState('');

  const onClose = () => {
    setSent(false);
    setSend(false);
    setFirmwareURL('');
    props.onClose();
  };

  const onSubmit = () => {
    setSend(true);
    if (firmwareURL && firmwareURL !== '') {
      props.moveClient.CreateEvent(props.deviceId, 'ForceUpgrade', {
        firmwareURL,
      });
      setStoredFirmwareUrl(firmwareURL);
      onClose();
    }
  };

  const onSubmitMAC = () => {
    setSend(true);
    if (mac && mac !== '') {
      props.moveClient.CreateEvent(props.deviceId, 'SetMAC', { mac });
      onClose();
    }
  };

  const onSubmitSetting = () => {
    setSend(true);
    if (deviceSetting && deviceSettingValue) {
      const jsonSetting = { [deviceSetting]: deviceSettingValue };

      props.moveClient.sendToDevice(
        props.deviceId,
        'updateConfig',
        jsonSetting,
      );
      onClose();
    }
  };

  const onChange = (nextURL: string) => {
    if (send) {
      setSend(false);
    }
    setFirmwareURL(nextURL);
  };

  const onChangeMAC = (macAddress: string) => {
    if (send) {
      setSend(false);
    }
    setMACAddress(macAddress);
  };

  const onChangeSetting = (deviceSetting: string) => {
    if (send) {
      setSend(false);
    }
    setDeviceSetting(deviceSetting);
  };

  const onChangeSettingValue = (deviceValue: string) => {
    if (send) {
      setSend(false);
    }
    setDeviceSettingValue(deviceValue);
  };

  return (
    <>
      <Dialog open={props.open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Force Upgrade</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" rowGap={2} sx={{ pt: 1 }}>
            {!sent && (
              <TextField
                label="Firmware URL"
                error={send && firmwareURL === ''}
                value={firmwareURL}
                onChange={(event) => onChange(event.target.value)}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          {!sent && (
            <Button variant="contained" onClick={onSubmit}>
              Send
            </Button>
          )}
        </DialogActions>
        <DialogTitle>
          Set Mac Address (DANGEROUS & Factory Resets Device on success)
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" rowGap={2} sx={{ pt: 1 }}>
            {!sent && (
              <TextField
                label="MAC Address (xx:xx:xx:xx:xx:xx)"
                error={send && mac === ''}
                value={mac}
                onChange={(event) => onChangeMAC(event.target.value)}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          {!sent && (
            <Button variant="contained" onClick={onSubmitMAC}>
              Set
            </Button>
          )}
        </DialogActions>
        <DialogTitle>Manual Setting</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" rowGap={2} sx={{ pt: 1 }}>
            {!sent && (
              <TextField
                label="Setting"
                error={send && deviceSetting === ''}
                value={deviceSetting}
                onChange={(event) => onChangeSetting(event.target.value)}
              />
            )}
          </Box>
          <Box display="flex" flexDirection="column" rowGap={2} sx={{ pt: 1 }}>
            {!sent && (
              <TextField
                label="Value"
                error={send && deviceSettingValue === ''}
                value={deviceSettingValue}
                onChange={(event) => onChangeSettingValue(event.target.value)}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          {!sent && (
            <Button variant="contained" onClick={onSubmitSetting}>
              Send
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
