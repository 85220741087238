import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

export default function Progress(props: {
  deviceBridgeConnected: boolean;
  accountInfoRetrieved: boolean;
  connectedToDevice: boolean;
  deviceInfoRetrieved: boolean;
  wifiCredentialsSet: boolean;
  devicePaired: boolean;
}) {
  const renderStep = (label: string, completed: boolean) => (
    <Box display="flex" flexDirection="row" alignItems="center" columnGap={1}>
      {completed && <CheckBoxOutlinedIcon fontSize="small" />}
      {!completed && <CheckBoxOutlineBlankIcon fontSize="small" />}
      <Typography fontSize="small">{label}</Typography>
    </Box>
  );

  return (
    <Card sx={{ mr: 2, p: 2 }}>
      <Box width={1} display="flex" flexDirection="column" rowGap={1} ml={2}>
        {renderStep('Connect to Device Bridge', props.deviceBridgeConnected)}
        {renderStep('Get Account Info', props.accountInfoRetrieved)}
        {renderStep('Connect to Device', props.connectedToDevice)}
        {renderStep('Get Device Info', props.deviceInfoRetrieved)}
        {renderStep('Set WiFi Credentials', props.wifiCredentialsSet)}
        {renderStep('Pair Device', props.devicePaired)}
      </Box>
    </Card>
  );
}
