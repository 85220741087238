import React from 'react';
import { Grid, Box, Card, CircularProgress, Typography } from '@mui/material';

import { MoveBrowserClient } from 'move-sdk';
import { useSelector } from 'react-redux';

export default function GetAccountId(props: {
  onAccountId: (accountId: string) => void;
}) {
  const session = useSelector((state: PepperState) => state.session);

  const accountIdCB = React.useCallback(() => {
    const client = new MoveBrowserClient(session.userData.serverData.wsPath);
    client.onConnected = () => {
      client.register(
        session.userData.account,
        () => {},
        'web',
        session.userData.token,
        'pepper',
      );
    };
    client.onRegistration = (data: any) => {
      client.unregister();
      client.disconnect();
      setTimeout(() => {
        props.onAccountId(data.account_id);
      }, 5000);
    };
    client.connect();
  }, [session]);
  React.useEffect(accountIdCB, [accountIdCB]);

  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        <Card sx={{ p: 4 }}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            rowGap={2}
          >
            <CircularProgress />
            <Typography variant="caption">Gathering Required Data</Typography>
            <Typography variant="caption">
              Do not exit out of this page or click any other links.
            </Typography>
          </Box>
        </Card>
      </Box>
    </Grid>
  );
}
