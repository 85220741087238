import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';

import { MoveBrowserClient } from 'move-sdk';
import CommandDialogForm from './CommandDialogForm';

export default function CommandDialog(props: {
  open: boolean;
  onClose: VoidFunction;
  moveClient: MoveBrowserClient;
  events: MoveEvent[];
}) {
  const theme = useTheme();
  const [commands, setCommands] = React.useState<string[]>([]);

  const handleCommands = (cmds: string) => {
    const rawCommands = cmds.split('\n');
    const validCommands: string[] = [];

    rawCommands.forEach((c) => {
      try {
        const asJson = JSON.parse(c);
        validCommands.push(JSON.stringify(asJson));
      } catch {
        // noop
      }
    });
    console.log(rawCommands);
    setCommands(validCommands);
  };

  const getCommandsNotSet = () => (
    <DialogContent sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
      <DialogContentText>
        Enter commands in the text box below. One command per line break and
        each must be a valid json object.
      </DialogContentText>
      <CommandDialogForm onClose={props.onClose} onCommands={handleCommands} />
    </DialogContent>
  );

  const getCommandController = () => {
    return (
      <>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}
        >
          <DialogContentText>Recognized Commands</DialogContentText>
          <Box
            sx={{
              background: theme.palette.grey[100],
              padding: 2,
              borderRadius: '5px',
              border: `2px dashed ${theme.palette.divider}`,
            }}
          >
            {commands.map((c, i) => {
              return (
                <Typography variant="caption" key={`cmd-${i}`}>
                  {i + 1} |&nbsp;&nbsp;<code>{c}</code>
                  <br />
                </Typography>
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCommands([]);
            }}
          >
            Reset
          </Button>
          <Button onClick={props.onClose}>Close</Button>
        </DialogActions>
      </>
    );
  };

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} maxWidth="md" fullWidth>
        <DialogTitle>Command</DialogTitle>
        {commands.length === 0 && getCommandsNotSet()}
        {commands.length !== 0 && getCommandController()}
      </Dialog>
    </>
  );
}
