import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  Typography,
} from '@mui/material';

import ExpandableSection from '../../../../Components/ExpandableSection';
import IroColorPicker from '../../../../Partials/IroColorPicker';
import PepperDeviceTemplate, { ConfigProps } from './Template';

export default function RGBWCBulbValidation(props: ValidationProps) {
  return (
    <PepperDeviceTemplate<BulbConfig>
      moveClient={props.moveClient}
      onChangeDevice={props.onChangeDevice}
      device={props.device}
      Config={RGBWCBulb}
    />
  );
}

//----------------------------------------------------------------------------------------------------------------------

const ledPatternOptions = [
  { key: 'none', value: 'none' },
  { key: 'night', value: 'night' },
  { key: 'reading', value: 'reading' },
  { key: 'cocktail', value: 'cocktail' },
  { key: 'leisure', value: 'leisure' },
  { key: 'soft', value: 'soft' },
  { key: 'blinking', value: 'blinking' },
  { key: 'rainbow', value: 'rainbow' },
  { key: 'rave', value: 'rave' },
  { key: 'nature', value: 'nature' },
];

//----------------------------------------------------------------------------------------------------------------------

const RGBWCBulb: React.FC<ConfigProps<BulbConfig>> = (props: {
  device: MoveDevice;
  config: BulbConfig;
  onConfigChange: (moveData: Partial<BulbConfig>) => void;
}) => {
  const getBrightnessConfig = () => {
    return (
      <Box width={1}>
        <Typography variant="caption">Brightness</Typography>
        <Box px={2}>
          <Slider
            color="secondary"
            value={props.config.bulbBrightness || 100}
            onChangeCommitted={(
              event: React.SyntheticEvent | Event,
              value: number | number[],
            ) => {
              props.onConfigChange({
                bulbBrightness: value as number,
              });
            }}
            step={1}
            valueLabelDisplay="auto"
            marks={[
              { value: 0, label: '0' },
              { value: 50, label: '50' },
              { value: 100, label: '100' },
            ]}
          />
        </Box>
      </Box>
    );
  };

  const getTemperatureConfig = () => {
    return (
      <Box width={1}>
        <Typography variant="caption">Color Temperature</Typography>
        <Box px={2}>
          <Slider
            color="secondary"
            value={props.config.bulbColorTemp || 2700}
            onChangeCommitted={(
              event: React.SyntheticEvent | Event,
              value: number | number[],
            ) => {
              props.onConfigChange({
                bulbColorTemp: value as number,
                bulbColor: '0',
              });
            }}
            step={1}
            valueLabelDisplay="auto"
            marks={[
              { value: 2700, label: '2700' },
              { value: 6500, label: '6500' },
            ]}
            min={2700}
            max={6500}
          />
        </Box>
      </Box>
    );
  };

  const getLedPatternConfig = () => {
    return (
      <Box width={1}>
        <Typography variant="caption">LED Pattern</Typography>
        <Select
          fullWidth
          size="small"
          value={props.config.ledPattern || ''}
          onChange={(e: SelectChangeEvent<any>) => {
            props.onConfigChange({
              ledPattern: e.target.value || '',
              bulbColor: '0',
              bulbColorTemp: 0,
            });
          }}
        >
          <MenuItem value="">&nbsp;</MenuItem>
          {ledPatternOptions.map((o) => (
            <MenuItem value={o.value} key={`led-pattern-${o.key}`}>
              {o.key}
            </MenuItem>
          ))}
        </Select>
      </Box>
    );
  };

  const getColorPicker = () => {
    return (
      <IroColorPicker
        value={props.config.bulbColor}
        onChange={(nextColor: string) => {
          props.onConfigChange({
            bulbColor: nextColor.replaceAll('#', ''),
          });
        }}
      />
    );
  };

  const getWarmChannel = () => (
    <Box width={1}>
      <Button
        fullWidth
        onClick={() => {
          const nextBulbColor =
            props.config.bulbColor === '000000' ? 'ffffff' : '000000';
          props.onConfigChange({
            bulbColor: nextBulbColor,
          });
        }}
      >
        Toggle Warm Channel
      </Button>
    </Box>
  );

  return (
    <>
      <ExpandableSection title="RGBWC Bulb Config" initiallyOpen>
        <Card sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardHeader subheader="Color Selection" />
          <CardContent
            sx={{
              px: 4,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignContent: 'space-between',
              columnGap: 4,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              rowGap={2}
              sx={{ width: '100%' }}
            >
              {getColorPicker()}
              {getWarmChannel()}
            </Box>

            <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
              {getBrightnessConfig()}
              {getTemperatureConfig()}
              {getLedPatternConfig()}
            </Box>
          </CardContent>
        </Card>
      </ExpandableSection>
    </>
  );
};
