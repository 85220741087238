const bridgeUrl = 'http://127.0.0.1:8213';

const bridgeRoutes = {
  ping: '/ping',
  devices: {
    list: '/devices/list',
    networks: '/devices/networks',
    pair: '/devices/pair',
    deviceInfo: '/devices/info/',
  },
};

const knownDevicePrefixes: string[] = [
  'ROBBI',
  'SPRCAM',
  'KNOK',
  'AXEL',
  'NIRO',
  'ARIA',
  'LOOC',
  'FLOOD',
  'CORI',
  'Merk',
  'MKRY',
  'MERKURY',
];

const ping = (): Promise<any> => {
  return fetch(`${bridgeUrl}${bridgeRoutes.ping}`)
    .then((res) => res.json())
    .then((res) => {
      if (res.status && res.status === 'ok') {
        return Promise.resolve(res.version);
      }
      return Promise.reject(res);
    });
};

const getSSIDs = (): Promise<string[]> => {
  const body = {
    knownDevicePrefixes,
  };
  return fetch(`${bridgeUrl}${bridgeRoutes.devices.list}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res && res.networks) {
        return res.networks;
      }
      return [];
    });
};

const getNetworks = (): Promise<string[]> => {
  const body = {
    knownDevicePrefixes,
  };
  return fetch(`${bridgeUrl}${bridgeRoutes.devices.networks}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res && res.networks) {
        return res.networks;
      }
      return [];
    });
};

const getDeviceInfo = (
  deviceSsid: string,
  host: string,
  port: number,
  includeWifiInfo: boolean,
): Promise<DeviceData> => {
  const body = {
    host,
    port,
    includeWifiInfo,
  };
  return fetch(`${bridgeUrl}${bridgeRoutes.devices.deviceInfo}${deviceSsid}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res && res.status === 'success' && res.data) {
        return res.data;
      } else {
        return Promise.reject(res);
      }
    });
};

const pair = (
  deviceSSID: string,
  host: string,
  port: number,
  instructionSet: ProvisionInstructions,
): Promise<ProvisionInstructions> => {
  const body = {
    deviceSSID,
    instructionSet,
    host,
    port,
  };
  return fetch(`${bridgeUrl}${bridgeRoutes.devices.pair}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status && res.status !== 'error') {
        return Promise.resolve(res.response);
      }
      return Promise.reject(res.error);
    });
};

const deviceBridge = {
  ping,
  getSSIDs,
  getNetworks,
  getDeviceInfo,
  pair,
};

export default deviceBridge;
