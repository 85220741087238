import sessionReducer from './session';

export const defaultState: PepperState = {
  session: {
    userData: {
      account: '',
      token: '',
      vendor: '',
      environment: '',
      serverData: {
        label: '',
        value: '',
        path: '',
        wsPath: '',
      },
    },
    hydrated: false,
    authenticated: false,
    fetch: {
      begin: false,
      end: false,
      error: false,
    },
  },
};

const initialState: PepperState = { ...defaultState };

const rootReducer = (state = initialState, action: any) => {
  if (sessionReducer[action.type]) {
    return sessionReducer[action.type](state, action);
  }

  return state;
};

export default rootReducer;
