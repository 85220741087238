import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import BaseShell from './Base';
import Login from '../routes/Login';
import Logout from '../routes/Logout';

import Validation from '../routes/Validation';
import ActionSelection from '../routes/ActionSelection';
import Pairing from '../routes/Pairing';
import Experimental from '../routes/Experimental';

const wrapInBase = (
  Component: JSX.Element,
  allowUnauthenticated = false,
): JSX.Element => {
  return (
    <BaseShell allowUnauthenticated={allowUnauthenticated}>
      {Component}
    </BaseShell>
  );
};

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={wrapInBase(<ActionSelection />)} />
        <Route path="/device" element={wrapInBase(<ActionSelection />)} />
        <Route path="/device/validation" element={wrapInBase(<Validation />)} />
        <Route
          path="/device/validation/:deviceType"
          element={wrapInBase(<Validation />)}
        />
        <Route path="/device/pairing" element={wrapInBase(<Pairing />)} />
        <Route path="/login" element={wrapInBase(<Login />, true)} />
        <Route path="/logout" element={wrapInBase(<Logout />, false)} />
        {window.location.origin.includes('localhost') && (
          <Route path="/experimental" element={wrapInBase(<Experimental />)} />
        )}
      </Routes>
    </BrowserRouter>
  );
}
