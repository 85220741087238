import React from 'react';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import WifiTwoToneIcon from '@mui/icons-material/WifiTwoTone';

import bridge from '../../../modules/bridge';

export default function ChooseDevice(props: {
  onDeviceSSID: (ssid: string) => void;
}) {
  const [selectedSSID, setSelectedSSID] = React.useState('');
  const [gotDevices, setGotDevices] = React.useState(false);
  const [getDevicesError, setGetDevicesError] = React.useState(false);
  const [foundDevices, setFoundDevices] = React.useState<string[]>([]);
  const getDevicesCB = React.useCallback(() => {
    if (!gotDevices && !getDevicesError) {
      bridge
        .getSSIDs()
        .then((res) => {
          if (res.length === 1) {
            setSelectedSSID(res[0]);
          }
          setGotDevices(true);
          setFoundDevices(res);
        })
        .catch((err) => {
          setGetDevicesError(true);
        });
    }
  }, [
    gotDevices,
    setGotDevices,
    getDevicesError,
    setGetDevicesError,
    setFoundDevices,
  ]);
  React.useEffect(getDevicesCB, [getDevicesCB, gotDevices]);

  const onSubmit = () => {
    if (selectedSSID !== '') {
      props.onDeviceSSID(selectedSSID);
    }
  };

  const onSubmitManualConnect = () => {
    props.onDeviceSSID('---');
  };

  if (!gotDevices) {
    return (
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Card sx={{ p: 4 }}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              rowGap={2}
            >
              <CircularProgress />
              <Typography variant="caption">Getting List of Devices</Typography>
            </Box>
          </Card>
        </Box>
      </Grid>
    );
  }

  if (getDevicesError) {
    return (
      <>
        error getting devices, todo handle, workaround refresh page and try
        again
      </>
    );
  }

  return (
    <Grid item xs={12} container justifyContent="center">
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column" rowGap={2}>
          <Card sx={{ px: 4, py: 2 }}>
            <Box display="flex" flexDirection="column" rowGap={2}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
                columnGap={1}
              >
                <Typography variant="h6">Connect to Device</Typography>
              </Box>
              <Typography variant="body2">
                Below are the devices found that are in softAP mode and ready to
                pair.
              </Typography>
              <Select
                fullWidth
                size="small"
                value={selectedSSID}
                onChange={(e: SelectChangeEvent) => {
                  setSelectedSSID(e.target.value);
                }}
              >
                <MenuItem value="" disabled>
                  Select A Device Network
                </MenuItem>
                {foundDevices.map((ssid) => {
                  return (
                    <MenuItem key={`ssid-${ssid}`} value={ssid}>
                      {ssid}
                    </MenuItem>
                  );
                })}
              </Select>
              <Box display="flex" flexDirection="row" columnGap={1}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => onSubmit()}
                >
                  Connect
                </Button>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    setGotDevices(false);
                    setGetDevicesError(false);
                  }}
                >
                  Scan Again
                </Button>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                rowGap={1}
                sx={{ pt: 2 }}
              >
                <Typography variant="h6">Manually Connect</Typography>
                <Typography variant="body2">
                  If the Device is not in the list or you have experienced
                  issues connecting automatically, you can connect to it
                  manually and then click Continue.
                </Typography>
                <Button
                  sx={{ width: 0.5 }}
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    onSubmitManualConnect();
                  }}
                >
                  Continue
                </Button>
              </Box>
            </Box>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
}
