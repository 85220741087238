import React from 'react';
import { Box } from '@mui/material';

import ExpandableCard from '../../../../../Components/ExpandableCard';

import { MoveBrowserClient } from 'move-sdk';
export default function DeviceSettings(props: {
  moveClient: MoveBrowserClient;
  deviceId: string;
}) {
  return (
    <ExpandableCard title="Settings" initiallyOpen>
      <Box display="flex" flexDirection="column" rowGap={2}></Box>
    </ExpandableCard>
  );
}
