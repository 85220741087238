import React from 'react';
import { Alert, Box, Typography } from '@mui/material';
import { format } from 'date-fns';

import { MoveBrowserClient } from 'move-sdk';
import ExpandableCard from '../../../../../../Components/ExpandableCard';
import CommandDialog from './CommandDialog';

export default function DeviceEvents(props: {
  events: MoveEvent[];
  moveClient: MoveBrowserClient;
}) {
  const [commandOpen, setCommandOpen] = React.useState(false);

  return (
    <>
      <ExpandableCard title="Events" initiallyOpen>
        <Box display="flex" flexDirection="column" rowGap={2}>
          <Alert variant="filled" severity="info">
            Only{' '}
            <span
              onClick={() => {
                setCommandOpen(true);
              }}
            >
              events
            </span>{' '}
            during the session will be displayed here.
          </Alert>

          <Box display="flex" flexDirection="column" rowGap={0}>
            {props.events.map((e) => {
              return (
                <Box display="flex" flexDirection="column" key={e.id}>
                  <Typography variant="caption">
                    {format(new Date(e.created), 'MM/dd/yyyy pp')} -{' '}
                    <b>{e.type}</b>
                    {e.body &&
                      e.body !== undefined &&
                      e.body !== 'undefined' && (
                        <Box sx={{ pl: 2 }}>{e.body}</Box>
                      )}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </ExpandableCard>
      <CommandDialog
        open={commandOpen}
        onClose={() => {
          setCommandOpen(false);
        }}
        moveClient={props.moveClient}
        events={props.events}
      />
    </>
  );
}
