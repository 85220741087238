import React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Typography,
} from '@mui/material';

import { MoveBrowserClient } from 'move-sdk';
import ExpandableCard from '../../../../../Components/ExpandableCard';
import chimeConfig from '../../../modules/chimeConfig';

const buttonProps: ButtonProps = {
  size: 'small',
  color: 'secondary',
  variant: 'outlined',
  fullWidth: true,
};

const selectProps: SelectProps = {
  fullWidth: true,
  size: 'small',
};

export default function ChimeInfoControl(props: {
  device: MoveDevice;
  moveClient: MoveBrowserClient;
  config: ChimeConfig;
}) {
  const [config, setConfig] = React.useState(props.config);
  const configChangeCB = React.useCallback(() => {
    setConfig(props.config);
  }, [setConfig, props.config]);
  React.useEffect(configChangeCB, [configChangeCB, props.config]);

  const onConfigUpdate = (
    key: keyof ChimeConfig,
    nextValue: string,
    moveData: any,
  ) => {
    const nextConfig = {
      ...config,
      [key]: nextValue,
    };
    setConfig(nextConfig);

    props.moveClient.sendToCamera(
      props.device.addresses[0].address,
      'updateConfig',
      moveData,
    );
  };
  const onEvent = (eventType: string) => {
    props.moveClient.CreateEvent(props.device.addresses[0].address, eventType);
  };

  return (
    <ExpandableCard title="Chime Settings" smallMargin initiallyOpen>
      <Box display="flex" flexDirection="column" rowGap={1}>
        <Box display="flex" flexDirection="row" columnGap={1}>
          <Button
            {...buttonProps}
            color="primary"
            size="medium"
            variant="contained"
            onClick={() => onEvent(chimeConfig.chimeEventType)}
          >
            Ring Chime
          </Button>
        </Box>

        <Box display="flex" flexDirection="row" columnGap={1}>
          <Box width={1}>
            <Typography variant="caption">Chime Volume</Typography>
            <Select
              {...selectProps}
              value={config?.chimeVolume || ''} // @TODO did this config property change at some point? Doesn't exist on config
              onChange={(e: SelectChangeEvent<any>) => {
                onConfigUpdate(
                  'chimeVolume',
                  e.target.value || '',
                  chimeConfig.chimeVolumeUpdate(e.target.value || ''),
                );
              }}
            >
              <MenuItem value="">&nbsp;</MenuItem>
              {chimeConfig.chimeVolumeOptions.map((o) => {
                return (
                  <MenuItem value={o} key={`chime-volume-${o}`}>
                    {o}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Box width={1}>
            <Typography variant="caption">Chime Duration</Typography>
            <Select
              {...selectProps}
              value={config?.sirenDur}
              onChange={(e: SelectChangeEvent<any>) => {
                onConfigUpdate(
                  'sirenDur',
                  e.target.value || '',
                  chimeConfig.sirenDurationUpdate(e.target.value || ''),
                );
              }}
            >
              <MenuItem value="">&nbsp;</MenuItem>
              {chimeConfig.sirenDurationOptions.map((o) => {
                return (
                  <MenuItem value={o} key={`sired-duration-${o}`}>
                    {o}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" columnGap={1}>
          <Grid item xs={6}>
            <Box width={1}>
              <Typography variant="caption">Ring Tone</Typography>
              <Select
                {...selectProps}
                value={config?.ringTone || ''}
                onChange={(e: SelectChangeEvent<any>) => {
                  onConfigUpdate(
                    'ringTone',
                    e.target.value || '',
                    chimeConfig.ringToneUpdate(e.target.value || ''),
                  );
                }}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {chimeConfig.ringToneOptions.map((o) => {
                  return (
                    <MenuItem value={o} key={`ring-tone-${o}`}>
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Grid>
        </Box>
        <Box display="flex" flexDirection="row" columnGap={1}>
          <Grid item xs={6}>
            <Box width={1}>
              <Typography variant="caption">Time Zone</Typography>
              <Select
                {...selectProps}
                value={config?.timeZone}
                onChange={(e: SelectChangeEvent<any>) => {
                  onConfigUpdate(
                    'timeZone',
                    e.target.value || '',
                    chimeConfig.timeZoneUpdate(e.target.value || ''),
                  );
                }}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {chimeConfig.timeZoneOptions.map((o) => {
                  return (
                    <MenuItem value={o} key={`timezone-${o}`}>
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Grid>
        </Box>
      </Box>
    </ExpandableCard>
  );
}
