const chimeEventType = 'Chime';

const chimeVolumeOptions = ['LOW', 'MED', 'HIGH'];
const chimeVolumeUpdate = (nextValue: string) => ({
  chimeVolume: nextValue,
});

const ringToneOptions = ['basic'];
const ringToneUpdate = (nextValue: string) => ({
  ringTone: nextValue,
});

const sirenDurationOptions = [
  '5 secs',
  '10 secs',
  '15 secs',
  '30 secs',
  '1 min',
  '2 mins',
  '5 mins',
];
const sirenDurationUpdate = (nextValue: string) => ({
  sirenDur: nextValue,
});

const timeZoneOptions = [
  'EST',
  'EST5EDT',
  'CST6CDT',
  'MST7MDT',
  'PST8PDT',
  'HST',
  'CET',
  'GB',
];
const timeZoneUpdate = (nextValue: string) => ({
  timeZone: nextValue,
});

const chimeConfig = {
  chimeEventType,
  chimeVolumeOptions,
  chimeVolumeUpdate,
  ringToneOptions,
  ringToneUpdate,
  sirenDurationOptions,
  sirenDurationUpdate,
  timeZoneOptions,
  timeZoneUpdate,
};

export default chimeConfig;
