import { Box, Typography } from '@mui/material';
import React from 'react';
import IroColorPickerExample from '../../Partials/IroColorPickerExample';
import IroColorPickerTooltip from '../../Partials/IroColorPickerTooltip';

export default function Experimental() {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        rowGap={3}
        sx={{ width: '100%' }}
      >
        <Typography>Experimental Features</Typography>
        <Box>
          <IroColorPickerExample />
        </Box>
        <Box>
          <IroColorPickerTooltip
            label="Segment 1"
            value="#ffffff"
            onChange={(s: string) => {}}
          />
        </Box>
      </Box>
    </>
  );
}
