import React from 'react';
import { Box, Button, Card, Fade, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export default function ExpandableError(props: {
  children: React.ReactNode;
  title: string;
  initiallyOpen?: boolean;
  onChange?: (nextState: boolean) => void;
  smallMargin?: boolean;
}) {
  const [open, setOpen] = React.useState(props.initiallyOpen ? true : false);

  return (
    <Grid item xs={12}>
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          columnGap={1}
        >
          <Button
            variant="text"
            sx={{ m: 0, p: 0, minWidth: 0 }}
            size="small"
            onClick={() => {
              if (props.onChange) {
                props.onChange(!open);
              }
              setOpen(!open);
            }}
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
          <Typography variant="body1">{props.title}</Typography>
        </Box>
        <Fade in={open}>
          <Box
            display="flex"
            flexDirection="column"
            rowGap={2}
            my={2}
            sx={{
              p: 2,
              borderRadius: '5px',
              border: '1px solid #C0C0C0',
              backgroundColor: '#F0F0F0',
              fontSize: 'smaller',
              display: !open ? 'none' : 'inline-block',
            }}
          >
            <code>{props.children}</code>
          </Box>
        </Fade>
      </Box>
    </Grid>
  );
}
