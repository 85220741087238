import { Box } from '@mui/material';
import React, { useRef } from 'react';

import iro from '@jaames/iro';

export default function IroColorPicker(props: {
  value?: string;
  onChange?: (nextHexColor: string) => void;
}) {
  const divRef = useRef(null);
  const [mounted, setMounted] = React.useState(false);
  const [colorPickerRef, setColorPickerRef] = React.useState<
    iro.ColorPicker | undefined
  >();

  const [value, setValue] = React.useState(props.value || '#ffffff');

  React.useEffect(() => {
    if (props.value && props.value !== value) {
      let nextValue = props.value;
      if (nextValue.includes('000000') || nextValue === '0') {
        nextValue = '#ffffff';
      }
      setValue(nextValue);
      colorPickerRef?.setColors([nextValue]);
    }
  }, [props.value]);

  React.useEffect(() => {
    if (divRef.current && !mounted) {
      const colorPicker = iro.ColorPicker(divRef.current, {
        layout: [
          {
            component: iro.ui.Wheel,
            options: {},
          },
        ],
        color: value,
      });
      colorPicker.on('color:init', () => {});
      colorPicker.on('input:end', (color: any) => {
        if (props.onChange) {
          props.onChange(color.hexString);
        }
      });
      colorPicker.on('mount', () => {
        setMounted(true);
      });
      setColorPickerRef(colorPicker);
    }
  }, [divRef, mounted]);

  return (
    <>
      <Box>
        <div ref={divRef}></div>
      </Box>
    </>
  );
}
