import React from 'react';
import { Box, Button, ButtonProps, Typography } from '@mui/material';

import ExpandableCard from '../../../../../Components/ExpandableCard';
import cameraConfig from '../../../modules/cameraConfig';

const buttonProps: ButtonProps = {
  size: 'small',
  color: 'secondary',
  variant: 'outlined',
  fullWidth: true,
};

export default function CameraInfoControl(props: {
  device: MoveDevice;
  info: CameraInfo | undefined;
  data: object | undefined;
  onSendCameraNotif: (clazz: string, data: any) => void;
  onSendCameraEvent: (eventType: string) => void;
}) {
  const [dataList, setDataList] = React.useState<JSX.Element[]>([]);

  const dataCB = React.useCallback(() => {
    const values = [];
    if (props.data) {
      for (const [k, v] of Object.entries(props.data)) {
        let renderValue = v;
        if (typeof v !== 'string') {
          renderValue = JSON.stringify(v);
        }
        values.push(
          <Typography variant="caption" key={k}>
            {k}: <b>{renderValue}</b>
          </Typography>,
        );
      }
    }
    setDataList(values);
  }, [setDataList, props.data]);
  React.useEffect(dataCB, [dataCB, props.data]);

  return (
    <>
      <ExpandableCard title="Camera Info" smallMargin>
        <Box display="flex" flexDirection="column" rowGap={1}>
          <Box display="flex" flexDirection="row" columnGap={1}>
            <Button
              {...buttonProps}
              onClick={() => {
                props.onSendCameraNotif(
                  cameraConfig.CameraInfoClazz,
                  undefined,
                );
              }}
            >
              Camera Info
            </Button>
            <Button
              {...buttonProps}
              onClick={() => {
                props.onSendCameraNotif(
                  cameraConfig.CameraSignalClazz,
                  undefined,
                );
              }}
            >
              Signal Info
            </Button>
            <Button
              {...buttonProps}
              onClick={() => {
                props.onSendCameraNotif(cameraConfig.CameraCPUClazz, undefined);
              }}
            >
              CPU Info
            </Button>
          </Box>

          <Box display="flex" flexDirection="row" columnGap={1}>
            <Button
              {...buttonProps}
              onClick={() => {
                props.onSendCameraNotif(
                  cameraConfig.CameraConfigClazz,
                  undefined,
                );
              }}
            >
              Dev Config
            </Button>
            <Button
              {...buttonProps}
              onClick={() => {
                props.onSendCameraNotif(
                  cameraConfig.CameraSDCardInfoClazz,
                  undefined,
                );
              }}
            >
              SD-Card Info
            </Button>
          </Box>

          <Box display="flex" flexDirection="row" columnGap={1}>
            <Button
              {...buttonProps}
              onClick={() => {
                props.onSendCameraEvent(cameraConfig.uploadLogEvent);
              }}
            >
              Upload Logfile
            </Button>
            <Button
              {...buttonProps}
              onClick={() => {
                props.onSendCameraEvent(cameraConfig.updateFirmwareEvent);
              }}
            >
              Firmware Update
            </Button>
          </Box>
          {props.data && (
            <Box display="flex" flexDirection="column" pt={2}>
              {dataList.map((x) => x)}
            </Box>
          )}
        </Box>
      </ExpandableCard>
    </>
  );
}
