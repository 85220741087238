import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4a8000',
    },
    secondary: {
      main: '#5c687c',
    },
    error: {
      main: '#FF0000',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

export default theme;
