import { Box, Card, Typography } from '@mui/material';
import React from 'react';
import IroColorPicker from './IroColorPicker';

export default function IroColorPickerExample() {
  return (
    <>
      <Card sx={{ p: 4 }}>
        <Box display="flex" flexDirection="column" rowGap={2}>
          <Typography>Iro Color Picker Example</Typography>

          <IroColorPicker
          // value="#ffffff"
          // onChange={() => {}}
          />
        </Box>
      </Card>
    </>
  );
}
