import React from 'react';
import { Box, Card, CircularProgress, Typography } from '@mui/material';

export default function LoadingCard(props: { msg?: string }) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      <Card sx={{ p: 4 }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          rowGap={2}
        >
          <CircularProgress />
          {props.msg && <Typography variant="caption">{props.msg}</Typography>}
        </Box>
      </Card>
    </Box>
  );
}
