import React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import sessionHooks from '../../redux/hooks/session';

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [once, setOnce] = React.useState(false);
  React.useEffect(() => {
    if (!once) {
      setOnce(true);
      sessionHooks
        .map(dispatch)
        .destroy()
        .then(() => navigate('/login'));
    }
  }, [once, setOnce, dispatch, navigate]);

  return <></>;
}
