import { Box, MenuItem, Select, Typography } from '@mui/material';
import { MoveBrowserClient } from 'move-sdk';
import ExpandableCard from '../../../../../Components/ExpandableCard';

const brightnessOptions = [
  { value: '', label: '' },
  { value: 20, label: '20%' },
  { value: 40, label: '40%' },
  { value: 60, label: '60%' },
  { value: 80, label: '80%' },
  { value: 100, label: '100%' },
];
const BrightnessConfig = (props: {
  brightness: number | string;
  onChange: (updBrightness: number) => void;
}) => (
  <Box>
    <Typography variant="caption">Brightness</Typography>
    <Box>
      <Select
        defaultValue={''}
        value={props.brightness || ''}
        size="small"
        className="w-1/2"
        onChange={(e) => {
          if (e.target.value) {
            props.onChange(e.target.value as number);
          }
        }}
      >
        {brightnessOptions.map((option) => (
          <MenuItem className="h-10" value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  </Box>
);

export default function DimmerSwitchConfig(props: {
  config: DimmerSwitchConfig | undefined;
  moveClient: MoveBrowserClient;
  onConfigChange: (key: any, nextValue: any, modeData: any) => void;
}) {
  return (
    <ExpandableCard title="Dimmer Switch Config" initiallyOpen smallMargin>
      <BrightnessConfig
        brightness={props.config?.brightness || ''}
        onChange={(updBrightness) =>
          props.onConfigChange('brightness', updBrightness, {
            brightness: updBrightness,
          })
        }
      />
    </ExpandableCard>
  );
}
