import ExpandableCard from '../../../../Components/ExpandableCard';
import {
  Alert,
  Box,
  Button,
  ButtonProps,
  Card,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@mui/material';
import { useState, ChangeEvent } from 'react';

import { MoveBrowserClient } from 'move-sdk';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

interface CommandPanelProps {
  deviceId: string;
  moveClient: MoveBrowserClient;
  onConfigChange?: (key: any, nextValue: any, moveData?: any) => void;
}

const buttonProps: ButtonProps = {
  size: 'small',
  color: 'secondary',
  variant: 'outlined',
  fullWidth: true,
};

const CommandPanel = (props: CommandPanelProps) => {
  const [commands, setCommands] = useState<string[]>(['']);
  const [error, setError] = useState<boolean>();
  const [activeCommandIndex, setActiveCommandIndex] = useState<number>(0);
  const handleCommandValues = (e: ChangeEvent<HTMLInputElement>) => {
    const str = e.target.value;
    // Create a new array of commands by splitting on newlines and filtering out empty strings
    let results = str.split('\n').filter((s) => s !== '');

    // If user types in non-json, catch error and show error message
    results.forEach((result, idx) => {
      try {
        JSON.parse(result);
        setError(false);
      } catch (e) {
        setError(true);
      }
    });
    setCommands(results || ['']);
  };

  const onConfigUpdate = (activeCommand: Record<string, string>) => {
    // break down the activeCommand object into an array of key/value pairs to iterate over
    const activeCommandsList = Object.entries(activeCommand);
    activeCommandsList.forEach(([key, nextValue]) => {
      if (props.onConfigChange) {
        props.onConfigChange(key, nextValue, activeCommand);
      }
      props.moveClient.sendToDevice(props.deviceId, 'updateConfig', {
        ...activeCommand,
      });
    });
  };

  const onClickNext = () => {
    if (activeCommandIndex !== commands.length - 1) {
      setActiveCommandIndex(activeCommandIndex + 1);
    }
  };
  const onClickPrevious = () => {
    if (activeCommandIndex !== 0) {
      setActiveCommandIndex(activeCommandIndex - 1);
    }
  };

  const onClickExecute = () => {
    const activeCommand = JSON.parse(commands[activeCommandIndex]);
    onConfigUpdate(activeCommand);
    onClickNext();
  };

  return (
    <ExpandableCard title="Command Panel" smallMargin initiallyOpen>
      <Box display="flex" flexDirection="column">
        <TextField
          multiline
          fullWidth
          onChange={handleCommandValues}
          inputProps={{
            style: { fontFamily: 'monospace', fontSize: 12 },
          }}
        />
        {commands[0] !== '' && commands.length ? (
          <Card variant="outlined" sx={{ marginTop: 1 }}>
            <List dense>
              {commands.map((command, idx) => {
                return (
                  <ListItem
                    onClick={() => setActiveCommandIndex(idx)}
                    key={command + idx}
                    sx={{
                      background:
                        activeCommandIndex === idx ? 'lightgray' : 'none',
                    }}
                  >
                    <ListItemText primary={command} />
                  </ListItem>
                );
              })}
            </List>
            {error ? (
              <Alert severity="error">Please enter valid JSON</Alert>
            ) : null}
          </Card>
        ) : null}
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <IconButton onClick={onClickPrevious} aria-label="delete">
            <ArrowUpward />
          </IconButton>
          <IconButton onClick={onClickNext} aria-label="delete">
            <ArrowDownward />
          </IconButton>
        </Box>
        <Box>
          <Button {...buttonProps} onClick={onClickExecute}>
            Execute
          </Button>
        </Box>
      </Box>
    </ExpandableCard>
  );
};

export default CommandPanel;
