import React from 'react';
import { v4 } from 'uuid';
import { Box, Grid } from '@mui/material';
import BlurOnIcon from '@mui/icons-material/BlurOn';

import CommonHeader from '../../Partials/CommonHeader';
import LoadingCard from '../../../../Components/LoadingCard';
import DeviceInfo from '../GenericDevice/Partials/DeviceInfo';
import ExtendedDeviceInfo from '../GenericDevice/Partials/ExtendedInfo';
import DeviceControl from '../GenericDevice/Partials/DeviceControl';
import DeviceEvents from '../GenericDevice/Partials/DeviceEvents';
import IndoorSmartPlugConfig from './Partials/IndoorSmartPlugConfig';
import CommandPanel from '../../Partials/command-panel';

export default function IndoorSmartPlug(props: ValidationProps) {
  const [deviceConfig, setDeviceConfig] = React.useState<
    IndoorSmartPlugConfig | undefined
  >();
  const onConfigChange = (
    key: keyof IndoorSmartPlugConfig,
    nextValue: any,
    multiOutletData?: any,
  ) => {
    if (deviceConfig) {
      const nextConfig: IndoorSmartPlugConfig = {
        ...deviceConfig,
        [key]: nextValue,
        ...multiOutletData,
      };
      setDeviceConfig(nextConfig);
    }
  };

  const [events, setEvents] = React.useState<MoveEvent[]>([]);
  const handleNewEvent = (data: any) => {
    let nextEvents = events;
    console.log('processing event', data);
    try {
      if (data.event.parent === props.device.addresses[0].address) {
        nextEvents = [data.event, ...events];
      }
    } catch {}
    setEvents(nextEvents);
  };

  const handleConfigUpdate = (newConfig: any) => {
    if (newConfig && newConfig.parms) {
      newConfig.parms.deviceId = newConfig.device_id;
      setDeviceConfig({
        ...newConfig.parms,
        createdAt: newConfig.created,
        lastPairedAt: newConfig.lastPairedAt,
        state: newConfig.state,
      });
      return;
    }
    const newEvent: MoveEvent = {
      account: '',
      created: new Date().toString(),
      id: v4(),
      media_key_1: '',
      name: '',
      parent: '',
      type: `updateConfig - ${JSON.stringify(newConfig)}`,
      updated: '',
    };
    setEvents([newEvent, ...events]);
  };

  props.moveClient.onConfigUpdate = handleConfigUpdate;
  props.moveClient.onNewEvent = handleNewEvent;
  props.moveClient.onNotification = handleNewEvent;

  const deviceConfigCB = React.useCallback(() => {
    if (!deviceConfig) {
      let deviceId = '';
      if (props.device.addresses.length !== 0) {
        deviceId = props.device.addresses[0].address;
      }
      if (deviceId !== '') {
        props.moveClient.onConfigUpdate = handleConfigUpdate;

        props.moveClient.getConfig(deviceId);
      } else {
        console.error('could not determine device id');
      }
    }
  }, [deviceConfig, setDeviceConfig, props.device, props.moveClient]);
  React.useEffect(deviceConfigCB, [deviceConfigCB, props.device]);

  const renderDevice = () => {
    return (
      <Grid item xs={12} container columnGap={4}>
        <Grid item xs={4}>
          <Box display="flex" flexDirection="column" rowGap={4}>
            <DeviceInfo config={deviceConfig} moveClient={props.moveClient} />
            <CommandPanel
              deviceId={props.device.addresses[0].address}
              moveClient={props.moveClient}
              onConfigChange={onConfigChange}
            />
            <DeviceControl
              deviceId={props.device.addresses[0].address}
              moveClient={props.moveClient}
              config={deviceConfig!}
              onConfigChange={onConfigChange}
            />
            <ExtendedDeviceInfo
              config={deviceConfig}
              moveClient={props.moveClient}
            />
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Box display="flex" flexDirection="column" rowGap={4}>
            <IndoorSmartPlugConfig />
            <DeviceEvents events={events} />
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid item xs={12} container justifyContent="center" sx={{ pb: 4 }}>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column" rowGap={2}>
          <CommonHeader
            Icon={<BlurOnIcon fontSize="large" />}
            viewTitle={props.device.label}
            onChangeDevice={props.onChangeDevice}
          />
          {!deviceConfig && <LoadingCard msg="Getting Device Config" />}
          {deviceConfig && renderDevice()}
        </Box>
      </Grid>
    </Grid>
  );
}
