import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Fade,
  Popper,
  useTheme,
} from '@mui/material';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import IroColorPicker from './IroColorPicker';

export default function IroColorPickerTooltip(props: {
  label: string;
  value: string;
  onChange: (nextValue: string) => void;
}) {
  const theme = useTheme();

  let buttonTextColor = theme.palette.text.primary;
  if (props.value.length === 6) {
    buttonTextColor = `#${props.value}`;
  }

  return (
    <PopupState variant="popper" popupId="demo-popup-popper">
      {(popupState) => (
        <div>
          <Button
            fullWidth
            variant="text"
            {...bindToggle(popupState)}
            sx={{ justifyContent: 'flex-start', color: buttonTextColor }}
          >
            {props.label} - {props.value ? `#${props.value}` : 'unset'}
          </Button>
          <Popper {...bindPopper(popupState)} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Card>
                  <CardHeader subheader={`${props.label} Color Selection`} />
                  <CardContent>
                    <IroColorPicker
                      value={props.value}
                      onChange={(nextHexColor: string) => {
                        props.onChange(nextHexColor);
                        popupState.close();
                      }}
                    />
                  </CardContent>
                </Card>
              </Fade>
            )}
          </Popper>
        </div>
      )}
    </PopupState>
  );
}
