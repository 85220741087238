import React from 'react';
import { Box, Button, Typography } from '@mui/material';

export default function CommonHeader(props: {
  Icon: JSX.Element;
  viewTitle: string;
  onChangeDevice: () => void;
}) {
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Box display="flex" flexDirection="row" columnGap={1}>
        {props.Icon}
        <Typography variant="h6">{props.viewTitle}</Typography>
      </Box>
      <Button onClick={props.onChangeDevice}>Change Device</Button>
    </Box>
  );
}
