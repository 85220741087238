import React from 'react';
import { Box, Button, ButtonProps, Typography } from '@mui/material';

import ExpandableCard from '../../../../../Components/ExpandableCard';
import cameraConfig from '../../../modules/cameraConfig';

import { MoveBrowserClient } from 'move-sdk';

const buttonProps: ButtonProps = {
  size: 'small',
  color: 'secondary',
  variant: 'outlined',
  fullWidth: true,
};

export default function DeviceInfoControl(props: {
  config: DeviceConfig | undefined;
  moveClient: MoveBrowserClient;
}) {
  const onDeviceInfo = (data: any) => {
    setData(data);
  };

  const [data, setData] = React.useState<any>({});

  React.useEffect(() => {
    props.moveClient.onDeviceInfo = onDeviceInfo;
    props.moveClient.onSignalInfo = onDeviceInfo;
    props.moveClient.onDeviceCpu = onDeviceInfo;

    props.moveClient.SendNotif(props.config?.deviceId || '', 'info', {});
  }, []);

  const [dataList, setDataList] = React.useState<JSX.Element[]>([]);

  const sendDeviceNotif = (clazz: string, data: any) => {
    props.moveClient.SendNotif(props.config?.deviceId || '', clazz, data);
  };

  const dataCB = React.useCallback(() => {
    const values = [];
    if (data) {
      for (const [k, v] of Object.entries(data)) {
        let renderValue = v;
        if (typeof v !== 'string') {
          renderValue = JSON.stringify(v);
        }
        values.push(
          <Typography variant="caption" key={k}>
            {k}: <b>{`${renderValue}`}</b>
          </Typography>,
        );
      }
    }
    setDataList(values);
  }, [setDataList, data]);
  React.useEffect(dataCB, [dataCB, data]);

  return (
    <>
      <ExpandableCard title="Extended Info" smallMargin initiallyOpen>
        <Box display="flex" flexDirection="column" rowGap={1}>
          <Box display="flex" flexDirection="row" columnGap={1}>
            <Button
              {...buttonProps}
              onClick={() => {
                sendDeviceNotif(cameraConfig.CameraInfoClazz, undefined);
              }}
            >
              Device Info
            </Button>
            <Button
              {...buttonProps}
              onClick={() => {
                sendDeviceNotif(cameraConfig.CameraSignalClazz, undefined);
              }}
            >
              Signal Info
            </Button>
          </Box>

          {data && (
            <Box display="flex" flexDirection="column" pt={2}>
              {dataList.map((x) => x)}
            </Box>
          )}
        </Box>
      </ExpandableCard>
    </>
  );
}
