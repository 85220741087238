import React from 'react';
import { Box, Button, ButtonProps } from '@mui/material';
import ExpandableCard from '../../../../../../Components/ExpandableCard';

import { MoveBrowserClient } from 'move-sdk';
import deviceConfig from '../../../../modules/deviceConfig';

const buttonProps: ButtonProps = {
  size: 'small',
  color: 'secondary',
  variant: 'outlined',
  fullWidth: true,
};

export default function DeviceControl(props: {
  deviceId: string;
  moveClient: MoveBrowserClient;
  overrideLabel?: string;
  config: DeviceConfig;
  onConfigChange?: (key: any, nextValue: any) => void;
}) {
  const [config, setConfig] = React.useState(props.config);
  const configChangeCB = React.useCallback(() => {
    setConfig(props.config);
  }, [setConfig, props.config]);
  React.useEffect(configChangeCB, [configChangeCB, props.config]);

  const onEvent = (eventType: string) => {
    props.moveClient.CreateEvent(props.deviceId, eventType);
  };

  const onConfigUpdate = (
    key: keyof DeviceConfig,
    nextValue: any,
    moveData: any,
  ) => {
    const nextConfig = {
      ...config,
      [key]: nextValue,
    };
    setConfig(nextConfig);
    if (props.onConfigChange) {
      props.onConfigChange(key, nextValue);
    }
    props.moveClient.sendToDevice(props.deviceId, 'updateConfig', moveData);
  };

  const onNotif = () => {
    props.moveClient.SendNotif(props.config.deviceId, 'info', {});
  };

  return (
    <ExpandableCard title={props.overrideLabel || 'Control'} initiallyOpen>
      <Box display="flex" flexDirection="column" rowGap={1}>
        <Box display="flex" flexDirection="row" columnGap={1}>
          <Button
            {...buttonProps}
            onClick={() => {
              onEvent(deviceConfig.events.reboot);
            }}
          >
            Reboot
          </Button>
          <Button
            {...buttonProps}
            onClick={() => {
              onEvent(deviceConfig.events.reset);
            }}
          >
            Reset
          </Button>
        </Box>

        <Box display="flex" flexDirection="row" columnGap={2}>
          <Button
            {...buttonProps}
            onClick={() => {
              const nextValue = config.powerStateOn === 0 ? 1 : 0;
              onConfigUpdate(
                'powerStateOn',
                nextValue,
                deviceConfig.configUpdates.powerStateToggle(nextValue),
              );
            }}
          >
            Toggle Power State
          </Button>
        </Box>
        <Box display="flex" flexDirection="row" columnGap={2}>
          <Button
            {...buttonProps}
            onClick={() => {
              onEvent(deviceConfig.events.ota);
            }}
          >
            Firmware Update (OTA)
          </Button>
        </Box>
        {/* <Box display="flex" flexDirection="row" columnGap={2}>
          <Button {...buttonProps}
            onClick={() => { onNotif() }}
          >
            Info
          </Button>
        </Box> */}
      </Box>
    </ExpandableCard>
  );
}
