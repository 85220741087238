import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import React from 'react';
import deviceBridge from '../../../modules/bridge';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

const currentVersion = '0.6.0';

export default function DeviceBridge(props: { onBridgeOnline: () => void }) {
  const [bridgeOnline, setBridgeOnline] = React.useState(false);
  const [bridgeInvalidVersion, setBridgeInvalidVersion] = React.useState(false);

  const checkBridgeCB = React.useCallback(() => {
    if (!bridgeOnline) {
      deviceBridge
        .ping()
        .then((res) => {
          if (res === currentVersion) {
            setBridgeOnline(true);
          } else {
            setBridgeInvalidVersion(true);
          }
        })
        .catch(() => {
          setTimeout(() => checkBridgeCB(), 6000);
        });
    }
  }, [bridgeOnline, setBridgeOnline, deviceBridge]);
  React.useEffect(checkBridgeCB, [checkBridgeCB]);

  return (
    <Grid item xs={12} container justifyContent="center">
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column">
          <Card sx={{ px: 4, py: 2 }}>
            <Box display="flex" flexDirection="column" rowGap={2}>
              <Typography variant="h6">Connect to Device Bridge</Typography>
              <Typography variant="body2">
                In order to provision a device you must download and run the
                most recent version of the Pepper Device Bridge.
              </Typography>
              <Typography variant="body2">
                Please ensure that you are using <b>Google Chrome</b> or{' '}
                <b>Mozilla Firefox</b>.
              </Typography>
              <Typography variant="caption">
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  columnGap={1}
                >
                  <NewReleasesIcon color="info" />
                  <b>Latest Release 0.6.0</b>
                </Box>
                <br />
                <Link href="/bridge/Pepper-Device-Bridge-0.6.0-mac.zip">
                  Mac (Intel x64)
                </Link>
                <br />
                <Link href="/bridge/Pepper-Device-Bridge-0.6.0-arm64-mac.zip">
                  Mac (M1 arm64)
                </Link>
                <br />
                <Link href="/bridge/Pepper-Device-Bridge-0.6.0-windows.zip">
                  Windows
                </Link>
                <br />
                <Link href="/bridge/pepper-device-bridge-0.6.0-linux.tar.gz">
                  Linux
                </Link>
              </Typography>
              {bridgeInvalidVersion && (
                <Alert severity="error">
                  Old version of Pepper Device Bridge Detected! You must
                  download the latest version above before you can continue.
                </Alert>
              )}
              <Button
                variant="contained"
                sx={{ mt: 2 }}
                onClick={() => {
                  props.onBridgeOnline();
                }}
                disabled={!bridgeOnline}
                startIcon={
                  bridgeOnline ? null : <CircularProgress size="1rem" />
                }
              >
                {bridgeOnline
                  ? 'Continue'
                  : 'Waiting for Device Bridge Response'}
              </Button>
            </Box>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
}
