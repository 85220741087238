import React from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { v4 } from 'uuid';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import { useSelector } from 'react-redux';

import { MoveBrowserClient } from 'move-sdk';
import deviceHelpers from './modules/deviceHelpers';

import CameraValidation from './Cameras/GenericCamera';
import ChimeValidation from './Devices/Chime';
import DeviceValidation from './Devices/GenericDevice';
import RGBWCBulbValidation from './Devices/Pepper/RGBWCBulb';
import RGBStripValidation from './Devices/Pepper/RGBStrip';
import RGBICStripValidation from './Devices/Pepper/RGBICStrip';
import IndoorSmartPlug from './Devices/IndoorSmartPlug';

import Emulation from '../Emulation';
import DimmerSwitch from './Devices/DimmerSwitch';

export default function Validation() {
  const session = useSelector((state: PepperState) => state.session);

  const [moveClient, setMoveClient] = React.useState<
    MoveBrowserClient | undefined
  >();
  const [moveClientReady, setMoveClientReady] = React.useState(false);
  const [moveRegistrationData, setMoveRegistrationData] = React.useState<any>();

  const [moveDevice, setMoveDevice] = React.useState<MoveDevice | undefined>();
  const [moveDeviceMap, setMoveDeviceMap] = React.useState<{
    [key: string]: ValidationDevices;
  }>(deviceHelpers.defaultDeviceMap());
  const [moveDeviceMapSet, setMoveDeviceMapSet] = React.useState(false);
  const [deviceType, setDeviceType] = React.useState('');
  const [useEmulation, setUseEmulation] = React.useState(false);

  const onChangeDevice = () => {
    setMoveDevice(undefined);
  };

  const moveSingletonCB = React.useCallback(() => {
    if (!moveClient && session.authenticated) {
      const client = new MoveBrowserClient(session.userData.serverData.wsPath);
      client.onConnected = () => {
        // client.register(session.userData.account, () => { }, `validation-${v4()}`, 'web', session.userData.token, 'pepper');
        client.register(
          session.userData.account,
          () => {},
          'web',
          session.userData.token,
          'pepper',
        );
      };
      client.onRegistration = (data: any) => {
        setMoveRegistrationData(data);
        setMoveClientReady(true);
      };
      client.connect();
      setMoveClient(client);
    }
  }, [moveClient, setMoveClient, session]);
  React.useEffect(moveSingletonCB, [moveSingletonCB]);

  React.useEffect(() => {
    return () => {
      if (moveClient) {
        console.log('unregistering move client');
        moveClient.unregister();
        moveClient.disconnect();
      }
    };
  }, [moveClient]);

  const moveDevicesCB = React.useCallback(() => {
    if (!moveDeviceMapSet && moveRegistrationData) {
      setMoveDeviceMapSet(true);
      const updatedDevices = deviceHelpers.defaultDeviceMap();
      moveRegistrationData?.services?.forEach((service: any) => {
        // Filter "." addresses representing sub-devices (xVR channels)
        if (service.addresses[0].address.includes('.')) {
          return;
        }
        let label = `${service.name}-${service.service_id}`;
        if (service.addresses.length !== 0) {
          label = `${service.name}-${service.addresses[0].address}`;
        }
        const device: MoveDevice = {
          label,
          id: service.service_id,
          addresses: service.addresses
            ? service.addresses.map((rawAddress: any) => ({
                address: rawAddress.address,
                addressType: rawAddress.addressType,
              }))
            : [],
          description: service.description,
          lastPairedAt: service.lastPairedAt,
          createdAt: service.createdAt,
        };
        // map devices to their corresponding type
        if (service && service.description) {
          const deviceDescription = service.description.toLowerCase();
          if (deviceHelpers.isCameraDevice(deviceDescription)) {
            updatedDevices['camera']?.devices.push(device);
          } else if (deviceHelpers.isChimeDevice(deviceDescription)) {
            updatedDevices['chime']?.devices.push(device);
          } else if (deviceHelpers.isRgbwcBulbDevice(deviceDescription)) {
            updatedDevices['rgbwcbulb']?.devices.push(device);
          } else if (deviceHelpers.isRgbStripDevice(deviceDescription)) {
            updatedDevices['rgbstrip']?.devices.push(device);
          } else if (deviceHelpers.isRgbwStripDevice(deviceDescription)) {
            updatedDevices['rgbwstrip']?.devices.push(device);
          } else if (deviceHelpers.isRgbicStripDevice(deviceDescription)) {
            updatedDevices['rgbicstrip']?.devices.push(device);
          } else if (deviceHelpers.isIndoorSmartPlug(deviceDescription)) {
            updatedDevices['indoorSmartPlug']?.devices.push(device);
          } else if (deviceHelpers.isDimmerSwitch(deviceDescription)) {
            updatedDevices['dimmerSwitch']?.devices.push(device);
          } else {
            device.label = `${device.label}-${service.description}`;
            updatedDevices['unknown']?.devices.push(device);
          }
        }
      });
      setMoveDeviceMap(updatedDevices);
    }
  }, [
    moveRegistrationData,
    setMoveDeviceMap,
    moveDeviceMapSet,
    setMoveDeviceMapSet,
  ]);
  React.useEffect(moveDevicesCB, [moveDevicesCB, moveRegistrationData]);

  const renderDevices = (
    label: string,
    type: string,
    devices: MoveDevice[],
    allowTypeSelection?: boolean,
  ) => {
    devices.sort((x, y) => {
      if (x.createdAt && y.createdAt) {
        return new Date(x.createdAt) > new Date(y.createdAt) ? 1 : -1;
      }
      return 0;
    });
    devices.sort((x, y) => {
      if (x.lastPairedAt && y.lastPairedAt) {
        return new Date(x.lastPairedAt) > new Date(y.lastPairedAt) ? 1 : -1;
      }
      return 0;
    });

    return (
      <Box
        display="flex"
        flexDirection="column"
        rowGap={1}
        pb={3}
        key={`device-${type}-${label}`}
      >
        {label}
        {allowTypeSelection && (
          <Alert severity="info">
            These devices are not configured by default but may be able to use
            one of the existing validation templates.
          </Alert>
        )}
        {allowTypeSelection && (
          <Box display="flex" flexDirection="row" columnGap={1}>
            <Grid item xs={5}>
              <Select
                fullWidth
                size="small"
                value={deviceType}
                onChange={(e: SelectChangeEvent) => {
                  setDeviceType(e.target.value);
                }}
              >
                <MenuItem disabled>Select a Validation Template</MenuItem>
                {deviceHelpers.supportedDeviceTypes.map((i) => {
                  return (
                    <MenuItem value={i} key={`device-type-${i}`}>
                      {i}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={7}>
              <Select
                fullWidth
                size="small"
                value={moveDevice ? moveDevice.id : ''}
                onChange={(e: SelectChangeEvent) => {
                  const device = devices.find(
                    (device) => device.id === e.target.value,
                  );
                  if (!device) {
                    console.error('error mapping device');
                  } else {
                    setMoveDevice(device);
                  }
                }}
              >
                <MenuItem value="" disabled>
                  Select a Device
                </MenuItem>
                {devices.map((device) => (
                  <MenuItem value={device.id} key={device.id}>
                    {device.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Box>
        )}
        {!allowTypeSelection && (
          <Select
            fullWidth
            size="small"
            value=""
            onChange={(e: SelectChangeEvent) => {
              const device = devices.find(
                (device) => device.id === e.target.value,
              );
              if (!device) {
                console.error('error mapping device');
              } else {
                setMoveDevice(device);
                setDeviceType(type);
              }
            }}
          >
            <MenuItem value="" disabled>
              Select a Device
            </MenuItem>
            {devices.map((device) => (
              <MenuItem value={device.id} key={device.id}>
                {device.label}
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>
    );
  };

  if (!moveClientReady) {
    return (
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Card sx={{ p: 4 }}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              rowGap={2}
            >
              <CircularProgress />
              <Typography variant="caption">
                Connecting to Pepper Move
              </Typography>
            </Box>
          </Card>
        </Box>
      </Grid>
    );
  }

  if (!moveRegistrationData) {
    return (
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Card sx={{ p: 4 }}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              rowGap={2}
            >
              <Alert severity="error">Failed to get Move Registration.</Alert>
            </Box>
          </Card>
        </Box>
      </Grid>
    );
  }

  if (useEmulation) {
    return (
      <>
        <Emulation moveClient={moveClient!} />
      </>
    );
  }

  if (!moveDevice || deviceType === '') {
    return (
      <Grid item xs={12} container justifyContent="center">
        <Grid item xs={6}>
          <Box display="flex" flexDirection="column" rowGap={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              columnGap={1}
            >
              <DeviceHubIcon />
              <Typography variant="h5">Select a Device</Typography>
            </Box>
            <Card sx={{ p: 4 }}>
              {Object.keys(moveDeviceMap).map((k) => {
                const deviceType = moveDeviceMap[k];
                if (deviceType && deviceType.devices.length !== 0) {
                  return (
                    deviceType.devices.length !== 0 &&
                    renderDevices(
                      deviceType.label,
                      k,
                      deviceType.devices,
                      k === 'unknown',
                    )
                  );
                }
                return <React.Fragment key={`device-type-${k}`} />;
              })}
            </Card>
          </Box>
        </Grid>
        {false && (
          <>
            <Grid item xs={12} sx={{ pb: 4 }} />
            <Grid item xs={6}>
              <Box display="flex" flexDirection="column" rowGap={2}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  columnGap={1}
                >
                  <DeviceHubIcon />
                  <Typography variant="h5">Become an Emu</Typography>
                </Box>
                <Card sx={{ p: 4 }}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      setUseEmulation(true);
                    }}
                  >
                    Camera
                  </Button>
                </Card>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    );
  }

  let DeviceValidationSchema: React.FC<ValidationProps> = DeviceValidation;
  switch (deviceType) {
    case 'camera':
      DeviceValidationSchema = CameraValidation;
      break;
    case 'chime':
      DeviceValidationSchema = ChimeValidation;
      break;
    case 'rgbwcbulb':
      DeviceValidationSchema = RGBWCBulbValidation;
      break;
    case 'rgbstrip':
    case 'rgbwstrip':
      DeviceValidationSchema = RGBStripValidation;
      break;
    case 'indoorSmartPlug':
      DeviceValidationSchema = IndoorSmartPlug;
      break;
    case 'rgbicstrip':
      DeviceValidationSchema = RGBICStripValidation;
      break;
    case 'dimmerSwitch':
      DeviceValidationSchema = DimmerSwitch;
      break;
  }
  return (
    <>
      {moveDeviceMap['unknown'].devices.includes(moveDevice) && (
        <Alert severity="warning" className="w-full mb-6">
          This device type is not mapped to a template. Unexpected behaviors or
          errors are likely to occur.
        </Alert>
      )}
      <DeviceValidationSchema
        moveClient={moveClient!}
        device={moveDevice!}
        onChangeDevice={onChangeDevice}
      />
    </>
  );
}
