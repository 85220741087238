import { authenticate, destroy } from '../actions/session';
import { Dispatch } from '../store';

const map = (dispatch: Dispatch) => ({
  authenticate: (
    server: string,
    username: string,
    password: string,
    vendor: string,
  ) => authenticate(server, username, password, vendor)(dispatch),
  destroy: () => destroy()(dispatch),
});

const exports = {
  map,
};

export default exports;
