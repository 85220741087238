import React from 'react';
import { Box, Button, Card, Divider, Grid, Typography } from '@mui/material';
import CloudDoneTwoToneIcon from '@mui/icons-material/CloudDoneTwoTone';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import CompassCalibrationTwoToneIcon from '@mui/icons-material/CompassCalibrationTwoTone';
import LiveHelpTwoToneIcon from '@mui/icons-material/LiveHelpTwoTone';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import { useNavigate } from 'react-router-dom';

export default function ActionSelection() {
  const navigate = useNavigate();
  return (
    <Grid item xs={12} container justifyContent="center">
      <Grid item xs={10}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          columnGap={2}
        >
          <Grid item xs={6}>
            <Card>
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  columnGap={3}
                  sx={{ p: 4 }}
                >
                  <CloudDoneTwoToneIcon
                    sx={{ fontSize: '3rem' }}
                    color="primary"
                  />
                  <Box display="flex" flexDirection="column">
                    <Typography variant="h5" color="primary">
                      Validation
                    </Typography>
                    <Typography variant="body2">
                      Test out Devices and Cameras paired to your Pepper
                      Account.
                    </Typography>
                  </Box>
                </Box>
                <Divider />
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  sx={{ py: 1 }}
                >
                  <Box>&nbsp;</Box>
                  <Box>
                    <Button
                      onClick={() => {
                        navigate('/device/validation');
                      }}
                    >
                      <ArrowForwardTwoToneIcon />
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  columnGap={3}
                  sx={{ p: 4 }}
                >
                  <CompassCalibrationTwoToneIcon
                    sx={{ fontSize: '3rem' }}
                    color="primary"
                  />
                  <Box display="flex" flexDirection="column">
                    <Typography variant="h5" color="primary">
                      Pairing
                    </Typography>
                    <Typography variant="body2">
                      Pair a new Device or Camera to your Pepper Account.
                    </Typography>
                  </Box>
                </Box>
                <Divider />
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  sx={{ py: 1 }}
                >
                  <Box>&nbsp;</Box>
                  <Box>
                    <Button
                      onClick={() => {
                        navigate('/device/pairing');
                      }}
                    >
                      <ArrowForwardTwoToneIcon />
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          columnGap={2}
          mt={2}
        >
          <Grid item xs={6}>
            <Card>
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  columnGap={3}
                  sx={{ p: 4 }}
                >
                  <LiveHelpTwoToneIcon
                    sx={{ fontSize: '3rem' }}
                    color="primary"
                  />
                  <Box display="flex" flexDirection="column">
                    <Typography variant="h5" color="primary">
                      Documentation
                    </Typography>
                    <Typography variant="body2">
                      See the Pepper Docs.
                    </Typography>
                  </Box>
                </Box>
                <Divider />
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  sx={{ py: 1 }}
                >
                  <Box>&nbsp;</Box>
                  <Box>
                    <Button
                      target="_blank"
                      href="https://dev.docs.pepper.me/docs/hardware-dev/validation-tool"
                    >
                      <ArrowForwardTwoToneIcon />
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={6}>
            {window.location.origin.includes('localhost') && (
              <>
                <Card>
                  <Box display="flex" flexDirection="column">
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      columnGap={3}
                      sx={{ p: 4 }}
                    >
                      <ScienceOutlinedIcon
                        sx={{ fontSize: '3rem' }}
                        color="primary"
                      />
                      <Box display="flex" flexDirection="column">
                        <Typography variant="h5" color="primary">
                          Experimental
                        </Typography>
                        <Typography variant="body2">
                          If you can see this and don't know why, you shouldn't
                          be seeing this {':)'}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider />
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      sx={{ py: 1 }}
                    >
                      <Box>&nbsp;</Box>
                      <Box>
                        <Button
                          onClick={() => {
                            navigate('/experimental');
                          }}
                        >
                          <ArrowForwardTwoToneIcon />
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </>
            )}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
