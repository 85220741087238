import React from 'react';
import { Grid, Box, Card, Typography, Button } from '@mui/material';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import { useNavigate } from 'react-router-dom';
import ExpandableError from '../../../Components/ExpandableError';

export default function Completion(props: {
  success: boolean;
  history: ProvisionInstructions;
}) {
  const navigate = useNavigate();

  const getSuccess = () => (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        columnGap={1}
      >
        <CheckBoxTwoToneIcon color="primary" />
        <Typography variant="h6" color="primary">
          Success
        </Typography>
      </Box>
      <Typography variant="body2">
        The device has been successfully paired to your account. Please allow a
        few minutes before it shows up in the list of recognized devices in
        Validation mode.
      </Typography>
    </>
  );

  const getFailure = () => (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        columnGap={1}
      >
        <ErrorTwoToneIcon />
        <Typography variant="h6">Something Went Wrong</Typography>
      </Box>
      <Typography variant="body2">
        Restart the device and ensure that it is in pairing mode. Then please
        try again. If issues persist, please share the error below.
      </Typography>
      <Grid item xs={12} pb={2}>
        <ExpandableError title="Reported Pairing Error" initiallyOpen>
          {JSON.stringify(props.history)}
        </ExpandableError>
      </Grid>
    </>
  );

  return (
    <Grid item xs={12} container justifyContent="center">
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column" rowGap={2}>
          <Card sx={{ px: 4, pt: 2, pb: 4 }}>
            <Box display="flex" flexDirection="column" rowGap={2}>
              {props.success && getSuccess()}
              {!props.success && getFailure()}
            </Box>
            <Button
              sx={{ mt: 4 }}
              variant="outlined"
              fullWidth
              onClick={() => {
                navigate('/device');
              }}
            >
              Continue
            </Button>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
}
