import React from 'react';
import { Grid, Box, Card, CircularProgress, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import bridge from '../../../modules/bridge';
import deviceProperties from '../module/deviceProperties';
import pairingInstructions from '../module/pairingInstructions';

export default function Pair(props: {
  deviceSSID: string;
  networkCreds: NetworkCreds;
  deviceData: DeviceData;
  accountId: string;
  onSuccessfulPair: (instr: ProvisionInstructions) => void;
  onFailedToPair: (instr: ProvisionInstructions) => void;
}) {
  const session = useSelector((state: PepperState) => state.session);

  const pairCB = React.useCallback(() => {
    const instructions = pairingInstructions.get(
      session,
      props.deviceData,
      props.accountId,
      props.networkCreds,
    );
    console.log(instructions);
    bridge
      .pair(
        props.deviceSSID,
        deviceProperties.defaultHost,
        deviceProperties.defaultPort,
        instructions,
      )
      .then((res) => {
        props.onSuccessfulPair(res);
      })
      .catch((err) => {
        props.onFailedToPair(err);
      });
  }, [
    pairingInstructions,
    props.deviceSSID,
    session,
    props.deviceData,
    props.accountId,
    props.networkCreds,
  ]);
  React.useEffect(pairCB, [pairCB]);

  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        <Card sx={{ p: 4 }}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            rowGap={2}
          >
            <CircularProgress />
            <Typography variant="caption">Pairing Device</Typography>
            <Typography variant="caption">
              This could take a few minutes. Do not exit out of this page or
              click any other links.
            </Typography>
          </Box>
        </Card>
      </Box>
    </Grid>
  );
}
