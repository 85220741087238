const events = {
  reboot: 'Reboot',
  reset: 'Reset',
  ota: 'OTA',
};

const configUpdates = {
  powerStateToggle: (nextValue: number, powerStateKey?: string) => {
    if (nextValue > 1) nextValue = 1;
    if (!powerStateKey) {
      return { powerStateOn: nextValue };
    }
    return { [powerStateKey as string]: nextValue };
  },
  powerStateToggleAll: (nextValue: number, keys: string[]) => {
    const powerStatesObj: Record<string, number> = {};

    for (let i = 0; i < keys.length; i++) {
      powerStatesObj[keys[i]] = nextValue;
    }

    return powerStatesObj;
  },
};

const deviceConfig = {
  events,
  configUpdates,
};

export default deviceConfig;
