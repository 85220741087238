import React from 'react';
import { v4 } from 'uuid';
import BlurOnIcon from '@mui/icons-material/BlurOn';

import CommonHeader from '../../Partials/CommonHeader';
import LoadingCard from '../../../../Components/LoadingCard';
import DeviceInfo from '../GenericDevice/Partials/DeviceInfo';
import ExtendedDeviceInfo from '../GenericDevice/Partials/ExtendedInfo';
import DeviceControl from '../GenericDevice/Partials/DeviceControl';
import DeviceEvents from '../GenericDevice/Partials/DeviceEvents';
import DimmerSwitchConfig from './Partials/DimmerSwitchConfig';
import CommandPanel from '../../Partials/command-panel';

export default function DimmerSwitch(props: ValidationProps) {
  const [deviceConfig, setDeviceConfig] = React.useState<
    DimmerSwitchConfig | undefined
  >();
  const onConfigChange = (
    key: keyof DimmerSwitchConfig,
    nextValue: any,
    moveData: any,
  ) => {
    if (deviceConfig) {
      const nextConfig: DimmerSwitchConfig = {
        ...deviceConfig,
        [key]: nextValue,
      };
      setDeviceConfig(nextConfig);
      props.moveClient.sendToDevice(
        props.device.addresses[0].address,
        'updateConfig',
        moveData,
      );
    }
  };

  const [events, setEvents] = React.useState<MoveEvent[]>([]);
  const handleNewEvent = (data: any) => {
    let nextEvents = events;
    console.log('processing event', data);
    try {
      if (data.event.parent === props.device.addresses[0].address) {
        nextEvents = [data.event, ...events];
      }
    } catch {}
    setEvents(nextEvents);
  };

  const handleConfigUpdate = React.useCallback(
    (newConfig: any) => {
      if (newConfig && newConfig.parms) {
        newConfig.parms.deviceId = newConfig.device_id;
        setDeviceConfig({
          ...newConfig.parms,
          createdAt: newConfig.created,
          lastPairedAt: newConfig.lastPairedAt,
          state: newConfig.state,
        });
        return;
      }
      const newEvent: MoveEvent = {
        account: '',
        created: new Date().toString(),
        id: v4(),
        media_key_1: '',
        name: '',
        parent: '',
        type: `updateConfig - ${JSON.stringify(newConfig)}`,
        updated: '',
      };
      setEvents([newEvent, ...events]);
    },
    [events],
  );

  props.moveClient.onConfigUpdate = handleConfigUpdate;
  props.moveClient.onNewEvent = handleNewEvent;
  props.moveClient.onNotification = handleNewEvent;

  const deviceConfigCB = React.useCallback(() => {
    if (!deviceConfig) {
      let deviceId = '';
      if (props.device.addresses.length !== 0) {
        deviceId = props.device.addresses[0].address;
      }
      if (deviceId !== '') {
        props.moveClient.onConfigUpdate = handleConfigUpdate;

        props.moveClient.getConfig(deviceId);
      } else {
        console.error('could not determine device id');
      }
    }
  }, [
    deviceConfig,
    props.device.addresses,
    props.moveClient,
    handleConfigUpdate,
  ]);
  React.useEffect(deviceConfigCB, [deviceConfigCB, props.device]);

  const renderDevice = () => {
    return (
      <div className="gap-8 w-[90%] grid grid-cols-3">
        <div className="w-full">
          <div className="flex flex-col gap-8">
            <DeviceInfo config={deviceConfig} moveClient={props.moveClient} />
            <DimmerSwitchConfig
              config={deviceConfig}
              moveClient={props.moveClient}
              onConfigChange={onConfigChange}
            />
            <CommandPanel
              deviceId={props.device.addresses[0].address}
              moveClient={props.moveClient}
              onConfigChange={onConfigChange}
            />
            <DeviceControl
              deviceId={props.device.addresses[0].address}
              moveClient={props.moveClient}
              config={deviceConfig!}
              onConfigChange={onConfigChange}
            />
            <ExtendedDeviceInfo
              config={deviceConfig}
              moveClient={props.moveClient}
            />
          </div>
        </div>
        <div className="w-full col-span-2">
          <DeviceEvents events={events} />
        </div>
      </div>
    );
  };

  return (
    <div className="justify-center pb-4 w-full">
      <div w-full>
        <div className="flex flex-col gap-6 w-full">
          <CommonHeader
            Icon={<BlurOnIcon fontSize="large" />}
            viewTitle={props.device.label}
            onChangeDevice={props.onChangeDevice}
          />
          {!deviceConfig && <LoadingCard msg="Getting Device Config" />}
          {deviceConfig && renderDevice()}
        </div>
      </div>
    </div>
  );
}
