import React from 'react';
import { Grid, Link, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

export default function BreadCrumb() {
  const session = useSelector((state: PepperState) => state.session);
  if (!session.authenticated) {
    return <></>;
  }
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ paddingTop: '0.25rem' }}
    >
      <Grid
        item
        xs={10}
        container
        direction="row"
        alignItems="right"
        justifyContent="right"
        spacing={2}
      >
        <Grid item>
          <Typography variant="caption">
            {session.userData.environment}
            &nbsp;|&nbsp;
            {session.userData.account}
            &nbsp;|&nbsp;
            <Link component={RouterLink} to="/logout">
              Logout
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
