import { defaultState } from '.';
import {
  SESSION_AUTHENTICATE,
  SESSION_DESTROY,
  SESSION_FETCH_BEGIN,
  SESSION_FETCH_ERROR,
  SESSION_HYDRATE,
} from '../actions/session';

const hydrate = (state: PepperState, action: any): PepperState => {
  if (!state.session.hydrated) {
    return {
      ...state,
      session: action.payload,
    };
  }
  return state;
};

const fetchBegin = (state: PepperState, action: any): PepperState => {
  return {
    ...state,
    session: {
      ...state.session,
      fetch: {
        begin: true,
        end: false,
        error: false,
      },
    },
  };
};

const fetchError = (state: PepperState, aciton: any): PepperState => {
  return {
    ...state,
    session: {
      ...state.session,
      fetch: {
        begin: true,
        end: true,
        error: true,
      },
    },
  };
};

const authenticate = (state: PepperState, action: any): PepperState => {
  return {
    ...state,
    session: {
      ...state.session,
      userData: action.payload,
      authenticated: true,
      hydrated: true,
      fetch: {
        begin: true,
        end: true,
        error: false,
      },
    },
  };
};

const destroy = (state: PepperState, action: any): PepperState => {
  return {
    ...defaultState,
  };
};

const sessionReducer = {
  [SESSION_HYDRATE.toString()]: hydrate,
  [SESSION_FETCH_BEGIN.toString()]: fetchBegin,
  [SESSION_FETCH_ERROR.toString()]: fetchError,
  [SESSION_AUTHENTICATE.toString()]: authenticate,
  [SESSION_DESTROY.toString()]: destroy,
};

export default sessionReducer;
