import React from 'react';
import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import WifiTwoToneIcon from '@mui/icons-material/WifiTwoTone';

export default function ListDevices(props: {
  knownNetworks: Network[];
  onNetworkCreds: (ssid: string, password: string) => void;
}) {
  const [selectedSSID, setSelectedSSID] = React.useState('');
  const [password, setPassword] = React.useState('');

  const onSubmit = () => {
    if (selectedSSID !== '' && password !== '') {
      let ssid = selectedSSID;
      if (props.knownNetworks.length === 0) {
        // user manually entered an ssid, clean non-unicode chars
        ssid = encodeURI(selectedSSID).toString().replace(/%/g, '\\x');
        console.log(ssid);
      }
      props.onNetworkCreds(ssid, password);
    }
  };

  return (
    <Grid item xs={12} container justifyContent="center">
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column" rowGap={2}>
          <Card sx={{ px: 4, pt: 2, pb: 4 }}>
            <Box display="flex" flexDirection="column" rowGap={2}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
                columnGap={1}
              >
                <WifiTwoToneIcon />
                <Typography variant="h6">Select A Device Network</Typography>
              </Box>
              <Typography variant="body2">
                Please choose a WiFi Network that your device will connect to.
              </Typography>
              <Box display="flex" flexDirection="column">
                {props.knownNetworks && props.knownNetworks.length !== 0 && (
                  <>
                    <Typography variant="caption">WiFi Network</Typography>
                    <Select
                      fullWidth
                      size="small"
                      value={selectedSSID}
                      onChange={(e: SelectChangeEvent) => {
                        setSelectedSSID(e.target.value);
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select A Device Network
                      </MenuItem>
                      {props.knownNetworks.map((ssid) => {
                        return (
                          <MenuItem key={`ssid-${ssid.ssid}`} value={ssid.ssid}>
                            {ssid.ssid}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </>
                )}
                {(!props.knownNetworks || props.knownNetworks.length === 0) && (
                  <Box display="flex" flexDirection="column">
                    <Typography variant="caption">SSID</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={selectedSSID}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setSelectedSSID(e.target.value);
                      }}
                    />
                  </Box>
                )}
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography variant="caption">Password</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  value={password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPassword(e.target.value);
                  }}
                />
              </Box>

              <Box display="flex" flexDirection="column" rowGap={1}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => onSubmit()}
                >
                  Continue
                </Button>
                {/* <Button
                  variant='outlined'
                  fullWidth
                  onClick={() => {
                    setGotDevices(false);
                    setGetDevicesError(false);
                  }}
                >
                  Scan Again
                </Button> */}
              </Box>
            </Box>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
}
